import { types, flow } from "mobx-state-tree";
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";

import Validator from 'validatorjs';

const rules = {
    current_password: 'required',
    password: 'confirmed|required',
};

const messages = {
    "required.current_password": 'A senha atual deve ser informada',
    "required.password": 'A senha deve ser informada',
    "confirmed.password": 'A senha não foi repetida corretamenta'
};

const Form = types.model({
    current_password: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    password_confirmation: types.optional(types.string, ""),

    current_passwordError: types.optional(types.string, ""),
    passwordError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
}).actions(self => ({

    onChangeCurrentPassword(value) {
        self.current_password = value;
        self.validate('current_password');
    },

    onChangePassword(value) {
        self.password = value;
        if (self.password_confirmation) {
            self.validate('password');
        }
    },

    onChangePasswordConfirmation(value) {
        self.password_confirmation = value;
        self.validate('password');
    },

    clearStateMessage() {
        self.stateMessage = ""
    },

    validate(field) {

        let _rules = {};
        if (field !== undefined) {
            _rules[field] = rules[field];
        } else {
            _rules = rules;
        }

        let validation = new Validator(self, _rules, messages);

        self.wasValidated = true;
        self.isValid = validation.passes();

        const errors = validation.errors.errors;
        Object.keys(_rules).forEach(function (key) {
            if (errors.hasOwnProperty(key)) {
                self[key + 'Error'] = errors[key][0];
            } else {
                self[key + 'Error'] = '';
            }
        });

    },

    changeState(state) {
        self.state = state
    },

    clear() {
        self.current_password = ""
        self.password = ""
        self.password_confirmation = ""

        self.passwordError = ""
        self.current_passwordError = ""

        self.isValid = false
        self.wasValidated = false
        self.state = "done"
        self.stateMessage = ""
    },

    save: flow(function* save() {
        self.state = "pending"

        try {
            api._token = StateStore.apiToken

            const response = yield api.changePassword({
                senha_atual: self.current_password,
                password: self.password,
                password_confirmation: self.password_confirmation
            });

            self.state = "done"
            self.stateMessage = response.message
            self.id = response.data.id
        } catch (error) {
            self.state = "error"
            self.stateMessage = error.message
        }
    }),
}));
const form = Form.create({ state: 'done' })
export default form;