import * as React from "react";
import { Page, Card, Button, Table, Grid, Form, Icon } from "tabler-react";
import Paginate from "../../components/Paginate";

class PageGrid extends React.Component {
    render() {
        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {row.name}
                </Table.Col>
                <Table.Col>
                    {row.email}
                </Table.Col>
                <Table.Col alignContent="center">
                    <Icon link name="edit" onClick={() => this.props.showForm(row.id)} />
                    <Icon link name="trash-2" onClick={() => this.props.onDelete(row.id)} />
                </Table.Col>
            </Table.Row>
        ));

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={3} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        );

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                Usuários
                                </Grid.Col>
                            <Grid.Col md={2}>

                                <Button
                                    onClick={() => this.props.showForm()}
                                    color="cyan"
                                    icon="plus"
                                >Novo registro</Button>

                            </Grid.Col>

                        </Grid.Row>
                    </Page.Title>
                </Page.Header>

                <Card
                    body={(
                        <Grid.Row>
                            <Grid.Col>
                                {/* <Form.Label>Nome</Form.Label> */}
                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <Form.Group label="Nome">
                                            <Form.Input name="nome"
                                                type="text"
                                                defaultValue={this.props.store.name}
                                                onChange={e => this.props.store.onChangeName(e.target.value)} />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <Form.Group label="Email">
                                            <Form.Input name="email"
                                                type="text"
                                                defaultValue={this.props.store.email}
                                                onChange={e => this.props.store.onChangeEmail(e.target.value)} />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col width={2}>
                                        <Button
                                            type='button'
                                            value='button'
                                            onClick={() => this.props.onChangePage(1)}
                                            color="cyan">
                                            Pesquisar
                                        </Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                    )}>

                </Card>
                <Card>
                    <Table
                        responsive
                        highlightRowOnHover
                        hasOutline
                        verticalAlign="center"
                        cards
                        className="text-nowrap"

                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.ColHeader>Nome</Table.ColHeader>
                                <Table.ColHeader>E-mail</Table.ColHeader>
                                <Table.ColHeader alignContent="center">Ações</Table.ColHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.length > 0 ? rows : noRows}
                            <Table.Row>
                                <Table.Col colSpan={3}>
                                    <Paginate
                                        rowsCount={this.props.store.rowsCount}
                                        limit={this.props.store.limit}
                                        currentPage={this.props.store.page}
                                        onChangePage={this.props.onChangePage} />
                                </Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </Card>
            </Page.Content>
        );
    }
}

export default PageGrid;