import { Component } from 'react'
import { showSwal, customToast } from '../store/ViewStore/Utils'

class BaseContainer extends Component {

    _stateStore = null
    _formStore = null
    _gridStore = null

    constructor(props) {
        super(props)
        this.state = { isEditMode: false }
    }

    set stateStore(stateStore) {
        this._stateStore = stateStore
    }

    set gridStore(gridStore) {
        this._gridStore = gridStore
    }

    set formStore(formStore) {
        this._formStore = formStore
    }

    componentDidUpdate() {

        this.isAuthenticated()

        const store = this.state.isEditMode ? this._formStore : this._gridStore
        if (store.stateMessage && store.state !== "pending") {
            this.showMessage(store)
        }
    }

    isAuthenticated() {
        if (!this._stateStore.isAuthenticated) {
            this.props.history.push('/login')
        }
    }

    showMessage(store) {
        const isError = store.state === "error"
        const icon = isError ? "error" : "info"
        const title = isError ? "Algo estranho aconteceu..." : ""
        customToast[icon](store.stateMessage, title)
        store.clearStateMessage()
    }

    onChangePage(page) {
        const offset = this._gridStore.limit * page - this._gridStore.limit
        this._gridStore.onChangeOffset(offset)
        this._gridStore.onChangePage(page)
        this.fetchRows()
    }

    async fetchRows() {
        return await this._gridStore.fetchRows()
    }

    async showForm(id) {
        if (id) {
            await this._formStore.fetch(id)
        } else {
            this._formStore.clear()
        }
        this.onSwitchComponent()
    }

    async onSave() {
        this._formStore.validate()
        return await this._formStore.isValid && this._formStore.save()
    }

    onDelete(id, swalOptions, callback) {
        if (!swalOptions) {
            swalOptions = {
                title: 'Confirma exclusão?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
                focusCancelButton: true
            }
        }
        showSwal(swalOptions)
            .then(async result => {
                if (result.value) {
                    await this._gridStore.delete(id)
                    callback && callback()
                }
            })
    }

    onBack() {
        this._gridStore.clearState()
        this.fetchRows()
        this.onSwitchComponent()
    }

    onSwitchComponent() {
        this.setState(state => ({ isEditMode: !state.isEditMode }))
    }
}

export default BaseContainer