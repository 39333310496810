import React, { Component } from "react";

import SiteWrapper from "@components/SiteWrapper.react";

class Senha extends Component {
    render() {
        return (
            <SiteWrapper isLoading={this.props.isLoading}>
                {this.props.component}
            </SiteWrapper>
        );
    }
}

export default Senha;