import { types, flow } from "mobx-state-tree";
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";
import JsonType from "@viewStore/JsonType";
import StringMask from "string-mask";

const GridStore = types
    .model({
        state: types.enumeration("State", ["pending", "done", "error"]),
        stateMessage: types.optional(types.string, ""),

        competencia: types.optional(types.string, ''),
        calculado: types.optional(types.string, ''),
        decreto_id: types.optional(types.string, ''),
        decretoSelect: types.optional(JsonType, []),

        rowsCount: types.optional(types.integer, 0),
        page: types.optional(types.integer, 1),
        limit: types.optional(types.integer, 30),
        offset: types.optional(types.integer, 0),

        rows: types.optional(JsonType, [])
    })
    .views(self => {
        return {
            get getCompetencia() {
                return StringMask.apply(self.competencia, '####/##')
            }
        }
    })
    .actions(self => ({

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
        },

        onChangeStatusRemessa(value) {
            self.calculado = value
        },

        onChangeDecreto(value) {
            console.log(value)
            self.decreto_id = value
        },

        onChangePage(value) {
            self.page = value
        },

        onChangeOffset(value) {
            self.offset = value
        },

        setDecretoSelect(value) {
            self.decretoSelect = value
        },

        clearState() {
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetchRows: flow(function* fetchRows() {
            self.rows = []
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.fetch(self);
                if (json.hasOwnProperty('data')) {
                    self.rows = json.data.rows;
                    self.rowsCount = json.data.total;
                }
                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        delete: flow(function* deleteRow(id) {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.delete(id)

                self.state = "done"
                self.stateMessage = json.message
                self.fetchRows()
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),
    }));

export default GridStore.create({ state: 'done' });