import { types, flow } from "mobx-state-tree";
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";
import JsonType from "@viewStore/JsonType";
import StringMask from "string-mask"

const GridStore = types
    .model({
        state: types.enumeration("State", ["pending", "done", "error"]),
        stateMessage: types.optional(types.string, ""),

        nome: types.optional(types.string, ""),
        cpf: types.optional(types.string, ""),

        rowsCount: types.optional(types.integer, 0),
        page: types.optional(types.integer, 1),
        limit: types.optional(types.integer, 30),
        offset: types.optional(types.integer, 0),

        rows: types.optional(JsonType, []),
    })
    .views(self => {
        return {
            get cpfMask() {
                return StringMask.apply(self.cpf, '000.000.000-00')
            }
        }
    })
    .actions(self => ({

        onChangeNome(value) {
            self.nome = value.trim()
        },

        onChangeCpf(value) {
            self.cpf = value.trim()
        },

        onChangePage(value) {
            self.page = value
        },

        onChangeOffset(value) {
            self.offset = value
        },

        clearState() {
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetchRows: flow(function* fetchRows() {
            self.rows = []
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.fetch(self);

                if (json.hasOwnProperty('data')) {
                    self.rows = json.data.rows;
                    self.rowsCount = json.data.total;
                }
                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        delete: flow(function* deleteUser(id) {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.delete(id)

                self.state = "done"
                self.stateMessage = json.message
                self.fetchRows()
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),
    }));

export default GridStore.create({ state: 'done' })