import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import Senha from './index'
import Form from "./form";

@inject("stateStore", "senhaFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);
        this.formStore = this.props.senhaFormStore
        this.stateStore = this.props.stateStore

        this.state = { isEditMode: true }

        this.isAuthenticated()
    }

    render() {
        // const { isEditMode } = this.state;

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}

                store={this._formStore} />
        );

        return (
            <Senha
                isLoading={this.props.senhaFormStore.state === 'pending'}
                component={formComponent}
            />
        );
    }
}

export default Container;