import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import JsonType from "@viewStore/JsonType"
import stringMask from "string-mask"

const GridStore = types
    .model({
        state: types.enumeration("State", ["pending", "done", "error"]),
        stateMessage: types.optional(types.string, ""),

        competencia: types.optional(types.string, ""),
        origem: types.maybe(JsonType, []),
        origemSelect: types.optional(JsonType, []),

        rowsCount: types.optional(types.integer, 0),
        page: types.optional(types.integer, 1),
        limit: types.optional(types.integer, 30),
        offset: types.optional(types.integer, 0),

        rows: types.optional(JsonType, []),
    })
    .views(self => {
        return {
            get getCompetencia() {
                return stringMask.apply(self.competencia, '####/##')
            }
        }
    })
    .actions(self => ({

        onChangeOrigem(origem) {
            self.origem = origem
        },

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
        },

        setOrigensSelect(origens) {
            self.origemSelect = origens
        },

        onChangePage(value) {
            self.page = value
        },

        onChangeOffset(value) {
            self.offset = value
        },

        clearState() {
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetchRows: flow(function* fetchRows() {
            self.rows = []
            self.state = "pending"
            try {
                const search = {...self}
                delete search['origemSelect']

                if (self.origem) {
                    search.origem_id = self.origem.value             
                }

                api._token = StateStore.apiToken
                const json = yield api.fetch(search)
                if (json.hasOwnProperty('data')) {
                    self.rows = json.data.rows
                    self.rowsCount = json.data.total
                }
                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        delete: flow(function* deleteRow(id) {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.delete(id)

                self.state = "done"
                self.stateMessage = json.message
                self.fetchRows()
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),
    }))

export default GridStore.create({ state: 'done' })