// @flow

import * as React from "react";
import { observer, inject } from "mobx-react";

import { Page, Grid } from "tabler-react";

import SiteWrapper from "@components/SiteWrapper.react";

@inject("stateStore")
@observer
class Index extends React.Component {
  render() {
    return (
      <SiteWrapper>
        <Page.Content title={ "Seja bem vindo(a) " + this.props.stateStore.user.name}  >
          <Grid.Row>
            <Grid.Col width={12}>
              {/* <Card>
                
                <Card.Body>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam deleniti
                    fugit incidunt, iste, itaque minima neque pariatur perferendis sed
                    suscipit velit vitae voluptatem. A consequuntur, deserunt eaque error
                    nulla temporibus!
                </Card.Body>

              </Card> */}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Index;
