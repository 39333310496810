import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import StringMask from "string-mask"
import JsonType from "@viewStore/JsonType"
import { validator } from "../../store/ViewStore/Utils"

const Form = types.model({
    id: types.optional(types.string, ""),
    competencia: types.optional(types.string, ""),
    retificado: types.optional(types.boolean, false),
    decreto_id: types.optional(types.string, ""),
    valor_repasse: types.maybeNull(types.string),
    desabilita_campo_valor_repasse: types.optional(types.boolean, true),
    valor_fpo: types.maybeNull(types.string),
    exibe_campo_fpo: types.optional(types.boolean, false),
    exibe_campo_origem: types.optional(types.boolean, false),
    origem_id: types.optional(types.string, ""),
    status: types.maybe(types.string),
    qtde_dias_uteis: types.optional(types.string, ''),
    desabilita_campo_qtde_dias_uteis: types.optional(types.boolean, true),

    decretoSelect: types.optional(JsonType, []),
    origemSelect: types.optional(JsonType, []),


    competenciaError: types.optional(types.string, ""),
    decreto_idError: types.optional(types.string, ""),
    valor_repasseError: types.optional(types.string, ""),
    valor_fpoError: types.optional(types.string, ""),
    origem_idError: types.optional(types.string, ""),
    qtde_dias_uteisError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get getCompetencia() {
                return StringMask.apply(self.competencia, '####/##')
            },

            get valorRepasse() {
                if (self.valor_repasse) {
                    let unmasked = self.valor_repasse.replace(/[^0-9]/g, '').replace(/^0+/, '')
                    return StringMask.apply(unmasked, '#.##0,00', { reverse: true })
                }
                return ""
            },

            get valorFPO() {
                if (self.valor_fpo) {
                    let unmasked = self.valor_fpo.replace(/[^0-9]/g, '').replace(/^0+/, '')
                    return StringMask.apply(unmasked, '#.##0,00', { reverse: true })
                }
                return ""
            },

            get qtdeDiasUteis() {
                return self.qtde_dias_uteis?.replace(/[^0-9]/g, '')
            },
        }
    })
    .actions(self => ({
        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
        },

        onChangeRetificado(isChecked) {
            self.retificado = isChecked
        },

        onChangeDecreto(value) {
            // resetando validacao
            self.competenciaError = ''
            self.decreto_idError = ''
            self.valor_repasseError = ''
            self.valor_fpoError = ''
            self.origem_idError = ''
            self.qtde_dias_uteisError = ''

            self.decreto_id = value

            self.exibe_campo_fpo = false
            self.valor_fpo = null

            self.exibe_campo_origem = false
            self.origem_id = ""

            self.desabilita_campo_qtde_dias_uteis = true
            self.qtde_dias_uteis = ''

            self.desabilita_campo_valor_repasse = true
            self.valor_repasse = null

            if (value) {
                const {
                    exibe_campo_fpo,
                    exibe_campo_origem,
                    exibe_campo_valor_repasse,
                    exibe_campo_dias_uteis
                } = self.decretoSelect.filter(decreto => decreto.id === value)[0]

                self.desabilita_campo_qtde_dias_uteis = !exibe_campo_dias_uteis
                self.desabilita_campo_valor_repasse = !exibe_campo_valor_repasse
                self.exibe_campo_fpo = exibe_campo_fpo
                self.valor_fpo = exibe_campo_fpo ? self.valor_fpo : null

                self.exibe_campo_origem = exibe_campo_origem
                self.origem_id = exibe_campo_origem ? self.origem_id : ""
            }
        },

        onChangeOrigem(value) {
            self.origem_id = value
        },

        onChangeValorRepasse(value) {
            const unmasked = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
            const number = unmasked ? (unmasked / 100).toFixed(2).toString() : ""
            self.valor_repasse = number
            self.validate('valor_repasse')
        },

        onChangeValorFPO(value) {
            const unmasked = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
            const number = unmasked ? (unmasked / 100).toFixed(2).toString() : ""
            self.valor_fpo = number
            self.validate('valor_fpo')
        },

        setDecretoSelect(value) {
            self.decretoSelect = value
        },

        setOrigemSelect(value) {
            self.origemSelect = value
        },

        setQtdeDiasUteis(value) {
            self.qtde_dias_uteis = value
        },

        changeState(value) {
            self.state = value
        },

        changeStatus(value) {
            self.status = value
        },

        validate() {
            const rules = {
                competencia: 'required',
                decreto_id: 'required'
            }

            const messages = {
                "required.competencia": 'A competência deve ser preenchida',
                "required.decreto_id": 'Selecione um decreto'
            }

            if (!self.desabilita_campo_qtde_dias_uteis) {
                rules['qtde_dias_uteis'] = 'required'
                messages['required.qtde_dias_uteis'] = 'A Qtde. dias úteis deve ser preenchida'
            }

            if (!self.desabilita_campo_valor_repasse) {
                rules['valor_repasse'] = 'required'
                messages['required.valor_repasse'] = 'A Qtde. dias úteis deve ser preenchida'
            }

            if (self.exibe_campo_fpo) {
                rules['valor_fpo'] = 'required'
                messages['required.qtde_valor_fpo'] = 'O valor FPO deve ser preenchido'
            }

            if (self.exibe_campo_origem) {
                rules['origem_id'] = 'required'
                messages['required.origem_id'] = 'Selecione a origem'
            }

            validator(self, rules, messages)
        },

        clear() {
            self.id = ""
            self.competencia = ""
            self.retificado = false
            self.decreto_id = ""
            self.valor_repasse = null
            self.valor_fpo = null
            self.exibe_campo_fpo = false
            self.origem_id = ""
            self.exibe_campo_origem = false
            self.status = undefined
            self.qtde_dias_uteis = ''

            self.decretoSelect = []
            self.origemSelect = []

            self.competenciaError = ""
            self.decreto_idError = ""
            self.valor_repasseError = ""
            self.valor_fpoError = ""
            self.origem_idError = ""

            self.isValid = false
            self.wasValidated = false
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetch: flow(function* fetch(id) {
            self.clear()
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.fetchOne(id)
                if (response.data) {
                    self.id = response.data.id
                    self.competencia = response.data.competencia
                    self.valor_repasse = response.data.valor_repasse
                    self.valor_fpo = response.data.valor_fpo
                    self.decreto_id = response.data.decreto_id
                    self.origem_id = response.data.origem_id || ""
                    self.status = response.data.status
                    self.retificado = !!response.data.parent_id
                    self.qtde_dias_uteis = response.data.qtde_dias_uteis?.toString() || ''
                }

                if (self.status === 'C') {
                    self.exibe_campo_fpo = false
                    self.exibe_campo_origem = false
                    self.desabilita_campo_qtde_dias_uteis = true
                    self.desabilita_campo_valor_repasse = true
                } else {
                    if (self.valor_fpo) self.exibe_campo_fpo = true
                    if (self.origem_id) self.exibe_campo_origem = true
                    if (self.qtde_dias_uteis) self.desabilita_campo_qtde_dias_uteis = false
                    if (self.valor_repasse) self.desabilita_campo_valor_repasse = false
                }


                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const response = yield api.save(self)

                self.state = "done"
                self.stateMessage = response.message
                self.id = response.data.id
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }))
const form = Form.create({ state: 'done' })
export default form