import React from 'react'

export default function Modal(props) {

    const sizes = {
        lg: 'modal-lg',
        sm: 'modal-sm'
    }

    if (props.show)
        document.body.style.overflow = 'hidden'
    else
        document.body.style.overflow = 'unset'

    return (
        <>
            <div className="modal fade show" style={{ display: props.show ? 'block' : 'none' }}>
                <div
                    className={`modal-dialog ${sizes[props.size]}`}
                    style={{ maxHeight: 'calc(100% - 3.5rem)', display: 'flex' }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.title}</h5>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'auto' }}>
                            {props.children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => props.close && props.close()}>Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            {props.show && (
                <div className={'modal-backdrop fade show'}></div>
            )}
        </>
    )
}