import config from "./configureStore";
import app from "./setup";

import { retrieveSettings, saveConfig } from "../services/storage";

const settings = retrieveSettings();

export default function(){
    const stores = config();

    if (settings) {
        stores.stateStore.setApiToken(settings.apiToken);
        stores.stateStore.setAuthenticated(settings.isAuthenticated);
        stores.stateStore.setUser(settings.user);
    } else {
        saveConfig(stores.stateStore);
    }
    

    return app(stores);
}

