import Api from '../../services/api_'

class Service extends Api {
    check(competencia, origem_id) {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/check/${competencia}/${origem_id}`,
            method: "GET",
            headers: headers,
            data: { competencia, origem_id }
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('competencias')