import * as React from "react"
import { Page, Card, Button, Form, Grid } from "tabler-react"
import { observer } from "mobx-react"
import Select from "react-select"

@observer
class PageForm extends React.Component {

    render() {

        const selectOrigem = (
            <Select
                closeMenuOnSelect={true}
                placeholder="Selecione uma origem"
                options={this.props.store.origemSelect}
                isClearable={true}
                onChange={options => this.props.store.onChangeOrigem(options)}
                value={this.props.store.origem}
                isDisabled={this.props.store.checkTodasAsOrigens}

                feedback={this.props.store.wasValidated ? this.props.store.origemError : ''}
                invalid={this.props.store.wasValidated && this.props.store.origemError}
            >
            </Select>
        )

        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Competência
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <Button color="primary" className="ml-auto" type="button" onClick={e => this.props.onSave()}>
                                    {this.props.store.id ? 'Atualizar' : 'Salvar'}
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row className='mb-5'>
                        <Grid.Col md={3}>
                            <Form.Checkbox
                                label="Definir para todas as origens"
                                checked={this.props.store.checkTodasAsOrigens}
                                onChange={(e) => this.props.store.onChangeCheckTodasOrigens(e.target.checked)}
                            />
                        </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>

                        <Grid.Col md={2}>
                            <Form.Input
                                label='Competência'
                                type='text'
                                value={this.props.store.getCompetencia}
                                onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                maxLength="7"
                                placeholder='AAAA/MM'

                                feedback={this.props.store.wasValidated ? this.props.store.competenciaError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.competenciaError}
                            />
                        </Grid.Col>

                        <Grid.Col md={2}>
                            <Form.Input
                                label='Dt. de abertura'
                                type='date'
                                value={this.props.store.data_abertura}
                                onChange={(e) => this.props.store.onChangeDataAbertura(e.target.value)}
                                placeholder='Dt. de abertura'

                                feedback={this.props.store.wasValidated ? this.props.store.data_aberturaError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.data_aberturaError}
                            />
                        </Grid.Col>

                        <Grid.Col md={2}>
                            <Form.Input
                                label='Dt. de fechamento'
                                type='date'
                                value={this.props.store.data_fechamento}
                                onChange={(e) => this.props.store.onChangeDataFechamento(e.target.value)}
                                placeholder='Dt. de fechamento'

                                feedback={this.props.store.wasValidated ? this.props.store.data_fechamentoError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.data_fechamentoError}
                            />
                        </Grid.Col>

                        <Grid.Col md={6}>
                            <Form.Label>Origem</Form.Label>
                            {selectOrigem}
                        </Grid.Col>

                    </Grid.Row>

                </Page.Card>
            </>
        )
    }
}

export default PageForm