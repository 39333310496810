import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import Competencia from './index'
import Form from "./form";
import Grid from "./grid";
import { customToast } from "../../store/ViewStore/Utils";

import origemService from "../origem/service"

@inject("stateStore", "competenciaGridStore", "competenciaFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.gridStore = this.props.competenciaGridStore
        this.formStore = this.props.competenciaFormStore
        this.stateStore = this.props.stateStore

        origemService._token = this._stateStore.apiToken
        this.origens();
        this.fetchRows()
    }

    async origens() {
        try {
            this._formStore.changeState("pending")
            const resp = await origemService.fetch()
            this._origens = resp.data.rows.map(origem => {
                return { value: origem.id, label: origem.nome }
            })
            this._formStore.changeState("done")
            this._gridStore.setOrigensSelect(this._origens)
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    showForm(id) {
        super.showForm(id)
        this._formStore.setOrigensSelect(this._origens)
    }

    render() {
        const { isEditMode } = this.state;

        const gridComponent = (
            <Grid
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        );

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}

                store={this._formStore} />
        );

        return (
            <Competencia
                isLoading={this._formStore.state === 'pending' || this._gridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        );
    }
}

export default Container;