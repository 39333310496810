import Api from '../../services/api_'

class Service extends Api {
    getOrigens() {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._apiUri}/api/usuario/origens`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('usuarios')