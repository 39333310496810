import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import JsonType from "@viewStore/JsonType";

import { validator } from "../../store/ViewStore/Utils"
import stringMask from "string-mask";

const rules = {
    competencia: 'required',
    data_abertura: 'required',
    data_fechamento: 'required'
}

const messages = {
    "required.competencia": 'A competência deve ser preenchida',
    "required.data_abertura": 'A data de abertura deve ser preenchida',
    "required.data_fechamento": 'A data de fechamento deve ser preenchida'
}

const Form = types.model({
    id: types.optional(types.string, ""),
    competencia: types.optional(types.string, ""),
    origem: types.maybe(JsonType, []),
    origemSelect: types.optional(JsonType, []),
    data_abertura: types.optional(types.string, ""),
    data_fechamento: types.optional(types.string, ""),

    checkTodasAsOrigens: types.optional(types.boolean, false),

    competenciaError: types.optional(types.string, ""),
    data_aberturaError: types.optional(types.string, ""),
    data_fechamentoError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get getCompetencia() {
                return stringMask.apply(self.competencia, '####/##')
            },

            get origem_id() {
                return self.origem.value
            },

            get getDataAbertura() {
                return stringMask.apply(self.data_abertura, '##/##/####')
            },
        }
    })
    .actions(self => ({

        onChangeCheckTodasOrigens(isChecked) {
            self.checkTodasAsOrigens = isChecked
            isChecked && (self.origem = [])
        },

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
            self.validate('competencia')
        },

        onChangeOrigem(origem) {
            self.origem = origem
        },

        onChangeDataAbertura(value) {
            self.data_abertura = value
            self.validate('data_abertura')
        },

        onChangeDataFechamento(value) {
            self.data_fechamento = value
            self.validate('data_fechamento')
        },

        setOrigensSelect(origens) {
            self.origemSelect = origens
            // self.validate('origem_id')
        },

        changeState(state) {
            self.state = state
        },

        clear() {
            self.id = ""
            self.competencia = ""
            self.data_abertura = ""
            self.data_fechamento = ""
            self.origem = []
            self.origemSelect = []
            self.checkTodasAsOrigens = false

            self.competenciaError = ""
            self.data_aberturaError = ""

            self.isValid = false
            self.wasValidated = false
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        validate(field) {
            validator(self, rules, messages, field)
        },

        fetch: flow(function* fetch(id) {
            self.clear()
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.fetchOne(id)
                if (response.data) {
                    self.id = response.data.id
                    self.competencia = response.data.competencia.toString()
                    self.data_abertura = response.data.data_abertura
                    self.data_fechamento = response.data.data_fechamento
                    self.origem = response.data.origem ? { value: response.data.origem.id, label: response.data.origem.nome } : []
                    self.checkTodasAsOrigens = !response.data.origem
                }

                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const response = yield api.save({...self, origem_id: self.origem_id || null})
                self.state = "done"
                self.stateMessage = response.message
                self.id = response.data.id
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }))

const form = Form.create({ state: 'done' })
export default form