import * as React from "react"
import { Page, Card, Grid, Dimmer, Table, Form, Button, Tag, Tooltip, Alert } from "tabler-react"
import { observer } from "mobx-react"
import If from "../../components/if"

@observer
class PageForm extends React.Component {

    render() {
        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    <Tooltip content={row.status_msg} placement="top">
                        <div>
                            <Tag color={row.status ? 'blue' : 'red'}>{row.status ? 'Pronto' : 'Erro'}</Tag>
                        </div>
                    </Tooltip>
                </Table.Col>
                <Table.Col>
                    {row.cns}
                </Table.Col>
                <Table.Col>
                    {row.matricula}
                </Table.Col>
                <Table.Col>
                    {row.origem}
                </Table.Col>
                <Table.Col>
                    {row.nome}
                </Table.Col>
                <Table.Col>
                    R$ {row.producao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                </Table.Col>
            </Table.Row>
        ));

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={6} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        );

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        Importar produção
                    </Page.Title>
                </Page.Header>

                <Card>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <Alert type="warning">
                                    <h5>Faça a importação da produção dos profissionais.</h5>
                                Informando a <strong>competência</strong> e enviando um arquivo, em excel, seguindo nosso <strong>modelo de planilha</strong>, é possível realizar a importação da produção dos profissionais.
                            </Alert>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <h3 className="mb-0 mt-4">Sua planilha já está no modelo padrão?</h3>
                                <p>Através do modelo de planilha, é possível inserir os dados de produção para a competência desejada</p>
                                <Table
                                    highlightRowOnHover
                                    verticalAlign="center"
                                    cards
                                    className="text-nowrap mt-5 mb-5 table-bordered table-sm"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColHeader>Competencia</Table.ColHeader>
                                            <Table.ColHeader>CNES</Table.ColHeader>
                                            <Table.ColHeader>CNS Profissional</Table.ColHeader>
                                            <Table.ColHeader>Producao</Table.ColHeader>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Col>
                                                2020/12
                                            </Table.Col>
                                            <Table.Col>
                                                2510913
                                            </Table.Col>
                                            <Table.Col>
                                                999999999999999
                                            </Table.Col>
                                            <Table.Col>
                                                R$ 1.200,35
                                            </Table.Col>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Col>
                                                2020/12
                                            </Table.Col>
                                            <Table.Col>
                                                2510914
                                            </Table.Col>
                                            <Table.Col>
                                                999999999999999
                                            </Table.Col>
                                            <Table.Col>
                                                R$ 3.500,20
                                            </Table.Col>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Col>
                                                2020/12
                                            </Table.Col>
                                            <Table.Col>
                                                2510903
                                            </Table.Col>
                                            <Table.Col>
                                                999999999999999
                                            </Table.Col>
                                            <Table.Col>
                                                R$ 780,00
                                            </Table.Col>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <p><strong>Observação:</strong> Todos os campos são de <strong>preenchimento obrigatório.</strong></p>
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <Button
                                    type='button'
                                    value='button'
                                    onClick={this.props.store.download}
                                    color="info"
                                    className="btn-sm"
                                >
                                    Baixar modelo de planilha
                                    </Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                </Card>

                <Card body={
                    <React.Fragment>
                        <Grid.Row>
                            <Grid.Col md={3}>
                                <Form.Checkbox
                                    label="Lançamentos retificados"
                                    checked={this.props.store.retificado}
                                    onChange={(e) => this.props.store.onChangeRetificado(e.target.checked)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={2}>
                                <Form.Group>
                                    <Form.Input
                                        type="text"
                                        label="Competência"
                                        value={this.props.store.getCompetencia}
                                        onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                        maxLength="7"
                                        placeholder='AAAA/MM'
                                    />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col width={10}>
                                <Form.Group>
                                    <Form.Label>Arquivo excel</Form.Label>
                                    <Form.FileInput
                                        label="Selecione um arquivo"
                                        onChange={e => this.props.store.setFile(e.target.files[0])} />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col width={2}>
                                <Button
                                    type='button'
                                    value='button'
                                    onClick={() => this.props.store.load()}
                                    color="cyan"
                                >
                                    Carregar
                                </Button>
                            </Grid.Col>
                        </Grid.Row>
                    </React.Fragment>
                } >
                </Card>
                <Dimmer active={this.props.isLoading} loader>
                    <Card>
                        <If test={rows.length > 0 && this.props.store.rows.some(row => row.status)}>
                            <Card.Body>
                                <Grid.Row>
                                    <Grid.Col width={4}>
                                        <Button
                                            color="primary"
                                            icon="arrow-down-circle"
                                            onClick={() => this.props.import()} >
                                            Importar
                                    </Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card.Body>
                        </If>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Status</Table.ColHeader>
                                    <Table.ColHeader>CNS</Table.ColHeader>
                                    <Table.ColHeader>Matrícula</Table.ColHeader>
                                    <Table.ColHeader>Origem</Table.ColHeader>
                                    <Table.ColHeader>Nome</Table.ColHeader>
                                    <Table.ColHeader>Vr. produção</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rows.length > 0 ? rows : noRows}
                            </Table.Body>
                        </Table>
                    </Card>
                </Dimmer>
            </Page.Content>
        )
    }
}

export default PageForm