// @flow

import * as React from "react";
import { observer, inject } from "mobx-react";

import { Page } from "tabler-react";

import SiteWrapper from "@components/SiteWrapper.react";

@inject("stateStore")
@observer
class Index extends React.Component {
  render() {
    return (
      <SiteWrapper>
        <Page.Content title='Você não tem permissão para acessar a página solicitada.' />
      </SiteWrapper>
    );
  }
}

export default Index;
