import { types} from "mobx-state-tree";

const JsonType = types.custom({
    name: "Json",
    fromSnapshot(value) {
        return JSON.parse(value);
    },
    toSnapshot(value) {
        return JSON.stringify(value);
    },
    isTargetType(value) {
        return value instanceof Object;
    },
    getValidationMessage(value) {
        try {
            JSON.parse(value);
        }
        catch (e) {
            return `'${value}' doesn't look like a valid JSON`;
        }
        return "";
    }
});

export default JsonType;