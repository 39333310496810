import { types, flow } from "mobx-state-tree";
import api from "../../services/api";
import StateStore from "../StateStore/StateStore";

export const LoginView = types
    .model('LoginView', {
        state: types.enumeration("State", ["pending", "done", "error"]),
        username:       types.optional(types.string, ""),
        password:       types.optional(types.string, ""),
        usernameError:  types.optional(types.string, ""),
        passwordError:  types.optional(types.string, ""),

        accessToken:    types.optional(types.string, ""),
        
        isValid:        types.optional(types.boolean, false),
        isAuthenticated: types.optional(types.boolean, false),
        wasValidated:   types.optional(types.boolean, false),

        
        stateMessage: types.optional(types.string, "")
    })
    .actions(self => ({

        auth: flow(function* auth() {
            self.state = 'pending'
            try {
              const json = yield  api.oauth(StateStore.apiUri, {
                username: self.username,
                password: self.password
              });
                                  
                //console.log(json);              
                if (json.hasOwnProperty('access_token')) {
                    self.accessToken        = json.access_token;
                    self.isAuthenticated    = true;
                  
                } else {
                    self.isAuthenticated     = false;
                }
                self.state = 'done'
            } catch (error) {
                self.state = 'error'
                self.isAuthenticated = false;
                self.stateMessage = error.message
            }
        }),
        
        usernameOnChange(value) {
            self.username = value;
            self.validateUsername();
        },
        
        validateUsername() {
            const usernamePatter = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            const required = self.username ? undefined : "O email é deve ser informado";
            self.usernameError = required
                ? required
                : usernamePatter.test(self.username)
                    ? undefined
                    : "Email parece ser inválido";
        },
        
        passwordOnChange(pwd) {
            self.password = pwd;
            self.validatePassword();
        },

        validatePassword() {
            self.passwordError = self.password.trim().length === 0 || !self.password ? "A senha deve ser informada" : undefined;
        },
        
        validateForm() {
            self.wasValidated = true;

            self.validatePassword();
            self.validateUsername();

            if (
                self.usernameError === "" &&
                self.passwordError === ""
            ) {
                self.isValid = true;
            }
        },

        clearState() {
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        clearStore() {
            self.username = "";
            self.isValid = false;
            self.isRegistered = false;
            self.usernameError = "";
            self.password = "";
            self.passwordError = "";
        },
    }));

const Store = LoginView.create({
    username: "",
    password: "",
    usernameError: "",
    passwordError: "",
    isValid: false,
    state: 'done'
});

export default Store;
