import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import StringMask from "string-mask"
import JsonType from "@viewStore/JsonType"


const Form = types.model({

    retificado: types.optional(types.boolean, false),
    competencia: types.optional(types.string, ""),
    file: types.frozen(),

    rows: types.optional(JsonType, []),

    competenciaError: types.optional(types.string, ""),
    origemError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get getCompetencia() {
                return StringMask.apply(self.competencia, '####/##')
            }
        }
    })
    .actions(self => ({

        onChangeRetificado(isChecked) {
            self.retificado = isChecked
        },

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
            self.rows = []
        },

        changeState(value) {
            self.state = value
        },

        setFile(file) {
            self.file = file
        },

        clear() {
            self.retificado = false
            self.competencia = ""
            self.rows = []

            self.isValid = false
            self.wasValidated = false
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        load: flow(function* () {
            self.state = "pending"
            self.rows = []

            try {
                api._token = StateStore.apiToken
                const formData = new FormData()
                formData.append('file', self.file || '')
                formData.append('competencia', self.competencia)
                formData.append('retificado', self.retificado)

                const response = yield api.load(formData)
                self.rows = Object.values(response.data)
                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        import: flow(function* () {
            self.state = "pending"
            try {
                const rows = self.rows.filter(row => row.status)
                const response = yield api.import({ 'lancamentos': rows })
                self.rows = []
                self.state = "done"
                self.stateMessage = response.message
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        download: flow(function* () {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const file = yield api.download()
                const blob = new Blob([file])
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'modelo.xlsx')
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)

                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }))
const form = Form.create({ state: 'done' })
export default form