import Api from '../../services/api_'

class Service extends Api {
    changePassword(data) {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._apiUri}${this._api}usuario/changePassword`,
            method: "POST",
            headers: headers,
            data: data
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('usuarios')