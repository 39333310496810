import { observer } from "mobx-react"
import * as React from "react"
import StringMask from "string-mask"
import { Alert, Badge, Button, Card, Form, Grid, Icon, Page, Table } from "tabler-react"
import If from "../../components/if"
import Modal from '../../components/Modal'
import Paginate from "../../components/Paginate"
import StatusRemessa from '../../components/StatusRemessa'

@observer
class PageGrid extends React.Component {

    render() {
        const selectDecreto = (
            <Form.Select
                label="Decreto"
                onChange={(e) => this.props.store.onChangeDecreto(e.target.value)}
                value={this.props.store.decreto_id}
            >
                <option value="">Selecione</option>
                {this.props.store.decretoSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.descricao}</option>)
                })}
            </Form.Select>
        )

        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {StringMask.apply(row.competencia, '0000/00')}
                </Table.Col>
                <Table.Col className="cargos-col-origem">
                    {row.decreto.descricao}
                </Table.Col>
                <Table.Col className="cargos-col-origem">
                    {row.origem ? row.origem.nome : '-'}
                </Table.Col>
                <Table.Col>
                    {Number(row.valor_repasse).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                </Table.Col>
                <Table.Col>
                    <Badge color={row.parent_id ? 'primary' : 'secondary'} >
                        {row.parent_id ? 'SIM' : 'NÃO'}
                        {row.numero_retificacao > 0 ? ' (' + row.numero_retificacao + 'ª RETIFICAÇÃO)' : ''}
                    </Badge>
                </Table.Col>
                <Table.Col>
                    <StatusRemessa status={row.status.valor} descricao={row.status.descricao} />
                </Table.Col>
                <Table.Col alignContent="center">
                    <If test={row.status.valor === 'P'}>
                        <Icon name="clock" />
                    </If>
                    <If test={row.status.valor !== 'P'}>
                        <If test={row.status.valor === 'E'}>
                            <div title="Lista de erros" style={{ display: 'inline' }}>
                                <Icon link name="list" onClick={() => this.props.store.onChangeShowModal(true, row.id)} />
                            </div>
                        </If>
                        <Icon link name="edit" onClick={() => this.props.showForm(row.id)} />
                        <Icon link name="trash-2" onClick={() => this.props.onDelete(row.id)} />
                        <If test={row.status.valor === 'C'}>
                            <Icon link name="rotate-ccw" onClick={() => this.props.revertRemessa(row.id)} />
                        </If>
                    </If>
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={7} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        )

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                Remessas
                            </Grid.Col>
                            <Grid.Col md={2}>

                                <Button
                                    onClick={() => this.props.showForm()}
                                    color="cyan"
                                    icon="plus">
                                    Novo registro
                                </Button>

                            </Grid.Col>

                        </Grid.Row>
                    </Page.Title>
                </Page.Header>

                <Card
                    body={(
                        <Grid.Row>
                            <Grid.Col>
                                <Grid.Row>
                                    <Grid.Col md={2}>
                                        <Form.Group>
                                            <Form.Input
                                                label='Competência'
                                                type='text'
                                                value={this.props.store.getCompetencia}
                                                onChange={e => this.props.store.onChangeCompetencia(e.target.value)}
                                                maxLength="7"
                                                placeholder='AAAA/MM'
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={2}>
                                        <Form.Select
                                            label="Status Remessa"
                                            value={this.props.store.calculado}
                                            onChange={e => this.props.store.onChangeStatusRemessa(e.target.value)}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="true">Calculado</option>
                                            <option value="false">Não calculado</option>
                                        </Form.Select>
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        {selectDecreto}
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col width={2}>
                                        <Button
                                            type='button'
                                            value='button'
                                            onClick={() => this.props.onChangePage(1)}
                                            color="cyan">
                                            Pesquisar
                                        </Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                    )}>

                </Card>
                <Card>
                    <Table
                        responsive
                        highlightRowOnHover
                        hasOutline
                        verticalAlign="center"
                        cards
                        className="text-nowrap mt-5"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.ColHeader>Competência</Table.ColHeader>
                                <Table.ColHeader>Decreto</Table.ColHeader>
                                <th style={{ minWidth: '320px' }}>Origem</th>
                                <Table.ColHeader>Valor repasse</Table.ColHeader>
                                <Table.ColHeader>Retificado</Table.ColHeader>
                                <Table.ColHeader>Status</Table.ColHeader>
                                <Table.ColHeader alignContent="center">Ações</Table.ColHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.length > 0 ? rows : noRows}
                            <Table.Row>
                                <Table.Col colSpan={7}>
                                    <Paginate
                                        rowsCount={this.props.store.rowsCount}
                                        limit={this.props.store.limit}
                                        currentPage={this.props.store.page}
                                        onChangePage={this.props.onChangePage} />
                                </Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </Card>

                <Modal
                    show={this.props.store.showModal}
                    size="lg"
                    close={() => this.props.store.onChangeShowModal(false)}
                    title="Lista de erros da remessa"
                >
                    {this.props.store.erros.map(e => (
                        <Alert key={e.id} type="danger">
                            {e.mensagem}
                        </Alert>
                    ))}
                </Modal>

            </Page.Content>
        )
    }
}

export default PageGrid