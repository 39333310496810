
import { types, flow } from "mobx-state-tree";
import { values } from "mobx"
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";
import JsonType from "@viewStore/JsonType";

import Validator from 'validatorjs';

const rules = {
    name: 'required',
    email: 'required|email',
    password: 'confirmed',
};

const messages = {
    "required.name": 'O nome deve ser preenchido',
    "required.email": 'O email deve ser preenchido',
    "email.email": 'O email não parece ser válido',
    "confirmed.password": 'A senha não foi repetida corretamenta',
};

const Permission = types.model({
    name: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
    checked: types.optional(types.boolean, false),
    isAdmin: types.optional(types.boolean, false),
    disabled: types.optional(types.boolean, false)
}).actions(self => ({
    toggle() {
        self.checked = !self.checked
    }
}))

const Form = types.model({
    id: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    password_confirmation: types.optional(types.string, ""),

    origens: types.optional(JsonType, []),
    origemSelect: types.optional(JsonType, []),

    permissions: types.map(Permission),
    userPermissions: types.optional(JsonType, []),

    nameError: types.optional(types.string, ""),
    emailError: types.optional(types.string, ""),
    passwordError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get getPermissions() {
                return values(self.permissions).filter(p => p.checked)
                    .map(p => p.value)
            }
        }
    })
    .actions(self => ({

        onChangeName(value) {
            self.name = value;
            self.validate('name');
        },

        onChangeEmail(value) {
            self.email = value;
            self.validate('email');
        },

        onChangePassword(value) {
            self.password = value;
            if (self.password_confirmation) {
                self.validate('password');
            }
        },

        onChangePasswordConfirmation(value) {
            self.password_confirmation = value;
            self.validate('password');
        },

        setOrigemSelect(origens) {
            self.origemSelect = origens
        },

        onChangeOrigem(origens) {
            self.origens = origens
        },

        setPermissionsCheck(permissions) {
            permissions.forEach(permission => {
                self.permissions.set(permission.id, Permission.create({
                    name: permission.label,
                    value: permission.value,
                    checked: false,
                    isAdmin: permission.value === 'super-admin'
                }))
            })
        },

        checkPermission(permission) {
            permission.toggle()
            const { checked, isAdmin } = permission

            if (isAdmin) {
                self.permissions.forEach(p => {
                    if (!p.isAdmin) {
                        p.disabled = checked
                        checked && (p.checked = false)
                    }
                })
            }
        },

        changeState(state) {
            self.state = state
        },

        clear() {
            self.id = ""
            self.name = ""
            self.email = ""
            self.password = ""
            self.password_confirmation = ""
            self.origens = []
            self.permissions.clear()
            self.userPermissions = []

            self.nameError = ""
            self.emailError = ""
            self.passwordError = ""

            self.isValid = false
            self.wasValidated = false
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        validate(field) {

            let _rules = {};
            if (field !== undefined) {
                _rules[field] = rules[field];
            } else {
                _rules = rules;
            }

            let validation = new Validator(self, _rules, messages);

            self.wasValidated = true;
            self.isValid = validation.passes();

            const errors = validation.errors.errors;
            Object.keys(_rules).forEach(function (key) {
                if (errors.hasOwnProperty(key)) {
                    self[key + 'Error'] = errors[key][0];
                } else {
                    self[key + 'Error'] = '';
                }
            });

        },

        fetch: flow(function* fetch(id) {
            self.clear();
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.fetchOne(id);

                if (response.data) {
                    self.id = response.data.id;
                    self.name = response.data.name;
                    self.email = response.data.email;
                    self.origens = response.data.origens.map(origem => {
                        return { value: origem.id, label: origem.nome }
                    })
                    self.userPermissions = response.data.permissions
                }

                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"

            try {
                api._token = StateStore.apiToken

                const response = yield api.save({
                    id: self.id,
                    name: self.name,
                    email: self.email,
                    password: self.password,
                    origens: self.origens || [],
                    permissions: self.getPermissions
                });

                self.state = "done"
                self.stateMessage = response.message
                self.id = response.data.id
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),
    }));
const form = Form.create({ state: 'done' })
export default form;