export const retrieveSettings = () => {
  let item = localStorage.getItem("@Settings:Queue");
  
  return (item ? JSON.parse(item) : null);
};

export const canAccess = path => {
  
  if (path === '/') return true

  const menus = retrieveSettings().user.menus
  return menus.some(menu => {
    if (menu.subItems) {
      return menu.subItems.some(subMenu => {
        return subMenu.to === path
      })
    }
    return menu.to === path
  })
}

export const saveToSettings = value => {
  const valueString = JSON.stringify(value);
  return localStorage.setItem("@Settings:Queue", valueString);
};

export const saveConfig = settings => {
  return saveToSettings({
    apiToken:             settings.apiToken,
    isAuthenticated:      settings.isAuthenticated,
    user:                 settings.user,
  });
};