import * as React from "react"
import { observer } from "mobx-react"
import { Page, Card, Table, Icon, Grid, Form, Dimmer, Button } from "tabler-react"
import stringMask from "string-mask"
import Paginate from "../../components/Paginate"

@observer
class PageGrid extends React.Component {

    render() {

        const selectDecreto = (
            <Form.Select
                label="Decreto"
                onChange={(e) => this.props.store.onChangeDecreto(e.target.value)}
                value={this.props.store.decreto_id}
            >
                <option value="">Selecione</option>
                {this.props.store.decretoSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.descricao}</option>)
                })}
            </Form.Select>
        )

        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {stringMask.apply(row.competencia, '0000/00')}
                </Table.Col>
                <Table.Col className="cargos-col-origem">
                    {`${row.descricao}`}
                </Table.Col>
                <Table.Col className="cargos-col-origem">
                    {`${row.nome}`}
                </Table.Col>
                <Table.Col>
                    {`${row.retificado ? 'Sim' : 'Não'}`}
                </Table.Col>
                <Table.Col alignContent="center">
                    <Icon link name="file-text" onClick={() => this.props.showReport(row.origem_id, row.competencia)} />
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={5} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        )

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        Produtividade
                    </Page.Title>
                </Page.Header>

                <Card body={
                    <Grid.Row>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col md={2}>
                                    <Form.Group>
                                        <Form.Input
                                            type="text"
                                            label="Competência"
                                            value={this.props.store.getCompetencia}
                                            onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                            maxLength="7"
                                            placeholder='AAAA/MM'
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={10}>
                                    {selectDecreto}
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col width={2}>
                                    <Button
                                        type='button'
                                        value='button'
                                        onClick={() => this.props.onChangePage(1)}
                                        color="cyan">
                                        Pesquisar
                                        </Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                } >
                </Card>
                <Dimmer active={this.props.isLoading} loader>
                    <Card>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Competência</Table.ColHeader>
                                    <Table.ColHeader>Decreto</Table.ColHeader>
                                    <Table.ColHeader>Origem</Table.ColHeader>
                                    <Table.ColHeader>Retificado</Table.ColHeader>
                                    <Table.ColHeader alignContent="center">Relatório</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rows.length > 0 ? rows : noRows}
                                <Table.Row>
                                    <Table.Col colSpan={7}>
                                        <Paginate
                                            rowsCount={this.props.store.rowsCount}
                                            limit={this.props.store.limit}
                                            currentPage={this.props.store.page}
                                            onChangePage={this.props.onChangePage} />
                                    </Table.Col>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Card>
                </Dimmer>
            </Page.Content>
        )
    }
}

export default PageGrid