import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import Colaborador from './index'
import Form from "./form";
import Grid from "./grid";
import { showSwal, customToast } from "../../store/ViewStore/Utils";

import usuarioService from "../usuario/service"
import origemService from "../origem/service"

@inject("stateStore", "colaboradorGridStore", "colaboradorFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.gridStore = this.props.colaboradorGridStore
        this.formStore = this.props.colaboradorFormStore
        this.stateStore = this.props.stateStore

        origemService._token = this._stateStore.apiToken
        usuarioService._token = this._stateStore.apiToken

        this.fetchRows()
    }

    async showForm(id) {
        super.showForm(id)

        try {
            this._formStore.changeState("pending")
            const origens = this.mountOrigemSelect(
                await this.getOrigens()
            )
            this._formStore.changeState("done")
            this._formStore.formLotacao.setOrigemSelect(origens)
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    mountOrigemSelect(origens, selectOrigens = []) {

        origens.forEach(origem => {
            if (origem['all_children_origens'].length > 0) {
                return this.mountOrigemSelect(origem['all_children_origens'], selectOrigens)
            }
            // Verifica se origem já existe na lista
            const origemFiltrada = selectOrigens.filter(item => item.id === origem.id)
            // Insere a origem caso não esteja na lista
            origemFiltrada.length === 0 && selectOrigens.push(origem)
        })

        return selectOrigens
    }

    async onChangeOrigem(origemId) {

        this._formStore.formLotacao.clear(true, true, false, false)
        this._formStore.formLotacao.setupFields()

        if (!origemId) {
            this._formStore.formLotacao.onChangeOrigem('')
            return
        }

        try {
            this._formStore.formLotacao.onChangeOrigem(origemId)
            this._formStore.changeState("pending")
            const categorias = await this.getCategorias(origemId)
            const cargos = await this.getCargos(origemId)
            this._formStore.formLotacao.setCategoriaSelect(categorias.data.rows || [])
            this._formStore.formLotacao.setCargoSelect(cargos.data.rows || [])
            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    async getOrigens() {
        return await usuarioService.getOrigens()
    }

    async getCategorias(origemId) {
        return await origemService.getCategorias(origemId)
    }

    async getCargos(origemId) {
        return await origemService.getCargos(origemId)
    }

    async onEditLotacao(idx) {

        if (!this.canManageLotacao(idx)) {
            this._formStore.changeState("error")
            customToast["error"]('Você não tem permissão para editar/excluir esta lotação.')
            return
        }

        if (this.canManageLotacao(idx)) {
            const origemId = this._formStore.lotacoes[idx].origem_id
            await this.onChangeOrigem(origemId)

            this._formStore.onEditLotacao(idx)
        }
    }

    onDeleteLotacao(idx) {
        if (!this.canManageLotacao(idx)) {
            this._formStore.changeState("error")
            customToast["error"]('Você não tem permissão para editar/excluir esta lotação.')
            return
        }

        const swalOptions = {
            title: 'Deseja remover a lotação selecionada?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ok!',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusCancelButton: true
        }
        showSwal(swalOptions)
            .then(async result => {
                if (result.value && this.canManageLotacao(idx)) {
                    this._formStore.removeLotacao(idx)
                }
            })
    }

    onCancelEditLotacao() {
        this._formStore.formLotacao.onCancelEditLotacao()
        this._formStore.formLotacao.clear()
    }

    canManageLotacao(idx) {
        const { origem_id } = this._formStore.lotacoes[idx]
        const origem = this._formStore.formLotacao.origemSelect.find(origem => origem.id === origem_id)
        return origem ? true : false
    }

    render() {
        const { isEditMode } = this.state;

        const gridComponent = (
            <Grid
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        );

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}
                onDeleteLotacao={(idx) => this.onDeleteLotacao(idx)}
                onEditLotacao={(idx) => this.onEditLotacao(idx)}
                onCancelEditLotacao={(idx) => this.onCancelEditLotacao(idx)}
                onChangeOrigem={(id) => this.onChangeOrigem(id)}

                store={this._formStore} />
        );

        return (
            <Colaborador
                isLoading={this._formStore.state === 'pending' || this._gridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        );
    }
}

export default Container;