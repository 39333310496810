import * as React from "react";
import { Page, Card, Button, Form, Grid, } from "tabler-react";
import { observer } from "mobx-react";
import Select from "react-select";
import makeAnimated from "react-select/animated"
import { values } from 'mobx'

@observer
class PageForm extends React.Component {

    render() {

        const animatedComponents = makeAnimated()

        const permissions = values(this.props.store.permissions).map((permission, idx) => {
            return (
                <Grid.Col md={12} key={idx}>
                    <Form.Checkbox
                        label={permission.name}
                        value={permission.value}
                        onChange={e => this.props.store.checkPermission(permission)}
                        checked={permission.checked}
                        disabled={permission.disabled}
                    />
                </Grid.Col>
            )
        })

        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Usuário
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <Button color="primary" className="ml-auto" type="button" onClick={e => this.props.onSave()}>
                                    {this.props.store.id ? 'Atualizar' : 'Salvar'}
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row>
                        <Grid.Col md={4}>
                            <Form.Input
                                label='Nome'
                                type='text'
                                defaultValue={this.props.store.name}
                                onChange={(e) => this.props.store.onChangeName(e.target.value)}
                                placeholder='Nome...'

                                feedback={this.props.store.wasValidated ? this.props.store.nameError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.nameError}
                            />

                        </Grid.Col>
                        <Grid.Col md={4}>
                            <Form.Input
                                label='E-mail'
                                type='text'
                                defaultValue={this.props.store.email}
                                onChange={(e) => this.props.store.onChangeEmail(e.target.value)}
                                placeholder='O usuário fará o login com essa informação'

                                feedback={this.props.store.wasValidated ? this.props.store.emailError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.emailError}
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            <Form.Input
                                label='Senha'
                                type='password'
                                onChange={(e) => this.props.store.onChangePassword(e.target.value)}
                                value={this.props.store.password}
                                feedback={this.props.store.wasValidated ? this.props.store.passwordError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.passwordError}
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            <Form.Input
                                label='Repita a senha'
                                type='password'
                                value={this.props.store.password_confirmation}
                                onChange={(e) => this.props.store.onChangePasswordConfirmation(e.target.value)}
                            />
                        </Grid.Col>

                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Col md={8}>
                            <Form.Label>Origens</Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder="Selecione a origem"
                                options={this.props.store.origemSelect}
                                defaultValue={this.props.store.origens}
                                onChange={options => this.props.store.onChangeOrigem(options)}
                            />
                        </Grid.Col>
                    </Grid.Row>

                    <h4 className="mt-3">Permissões</h4>
                    <hr />

                    <Grid.Row>
                        {permissions}
                    </Grid.Row>

                </Page.Card>
            </>
        );
    }
}

export default PageForm;