import Api from '../../services/api_'

class Service extends Api {
    calcular(id) {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/calculo/${id}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    reabrir(id) {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/reabrir/${id}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('remessas')