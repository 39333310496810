import Api from '../../services/api_'

class Service extends Api {
    getCategorias(origemId) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${origemId}/categorias`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    getCargos(origemId) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${origemId}/cargos`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    getLotacoes(origemId, competencia, retificado) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${origemId}/lotacoes/${competencia}/${retificado}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    getProducao(origemId, competencia) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${origemId}/producao/${competencia}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('origens')