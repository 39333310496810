import * as React from "react"
import { observer } from "mobx-react"
import { Page, Card, Button, Table, Grid, Form, Icon } from "tabler-react"
import Paginate from "../../components/Paginate"
import stringMask from "string-mask"
import { formatDate } from "../../store/ViewStore/Utils"
import Select from "react-select"

@observer
class PageGrid extends React.Component {

    render() {

        const selectOrigem = (
            <Select
                closeMenuOnSelect={true}
                placeholder="Selecione uma origem"
                options={this.props.store.origemSelect}
                isClearable={true}
                onChange={options => this.props.store.onChangeOrigem(options)}
                value={this.props.store.origem}

                feedback={this.props.store.wasValidated ? this.props.store.origemError : ''}
                invalid={this.props.store.wasValidated && this.props.store.origemError}
            >
            </Select>
        )

        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {stringMask.apply(row.competencia, '0000/00')}
                </Table.Col>
                <Table.Col>
                    {row.origem ? row.origem.nome : 'TODAS'}
                </Table.Col>
                <Table.Col>
                    de {formatDate(row.data_abertura)} à {formatDate(row.data_fechamento)}
                </Table.Col>
                <Table.Col alignContent="center">
                    <Icon link name="edit" onClick={() => this.props.showForm(row.id)} />
                    <Icon link name="trash-2" onClick={() => this.props.onDelete(row.id)} />
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={4} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        )

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                Competências
                                </Grid.Col>
                            <Grid.Col md={2}>

                                <Button
                                    onClick={() => this.props.showForm()}
                                    color="cyan"
                                    icon="plus">
                                    Novo registro
                                </Button>

                            </Grid.Col>

                        </Grid.Row>
                    </Page.Title>
                </Page.Header>

                <Card
                    body={(
                        <Grid.Row>
                            <Grid.Col>
                                <Grid.Row>
                                    <Grid.Col md={2}>
                                        <Form.Group>
                                            <Form.Input
                                                type="text"
                                                label="Competência"
                                                value={this.props.store.getCompetencia}
                                                onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                                maxLength="7"
                                                placeholder='AAAA/MM'
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={10}>
                                        <Form.Label>Origem</Form.Label>
                                        {selectOrigem}
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col width={2}>
                                        <Button
                                            type='button'
                                            value='button'
                                            onClick={() => this.props.onChangePage(1)}
                                            color="cyan"
                                        >
                                            Pesquisar
                                        </Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                    )}>

                </Card>
                <Card>
                    <Table
                        responsive
                        highlightRowOnHover
                        hasOutline
                        verticalAlign="center"
                        cards
                        className="text-nowrap"

                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.ColHeader>Competência</Table.ColHeader>
                                <Table.ColHeader>Origem</Table.ColHeader>
                                <Table.ColHeader>Período</Table.ColHeader>
                                <Table.ColHeader alignContent="center">Ações</Table.ColHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.length > 0 ? rows : noRows}
                            <Table.Row>
                                <Table.Col colSpan={4}>
                                    <Paginate
                                        rowsCount={this.props.store.rowsCount}
                                        limit={this.props.store.limit}
                                        currentPage={this.props.store.page}
                                        onChangePage={this.props.onChangePage} />
                                </Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </Card>
            </Page.Content>
        )
    }
}

export default PageGrid