import axios from 'axios'
import StateStore from "../store/StateStore/StateStore";
import { saveConfig } from "../services/storage";
import qs from 'querystring'

class Api {

    _token = null
    _baseUrl = null
    _apiUri = null

    constructor(resource) {
        this._api = '/api/v1/'
        this._resource = resource
        this._apiUri = StateStore.apiUri

        this._headers = {
            'Accept': 'application/json'
        }

        this._baseUrl = `${this._apiUri}${this._api}${this._resource}`
    }

    _makeRequest(requestData) {
        return axios(requestData)
            .then(resp => resp.data)
            .catch(error => {

                if (!error.response) throw Error("Não foi possível conectar com o servidor. <br/> Entre em contato com o administrador do sistema")

                if (error.response.status === 401) {
                    StateStore.setAuthenticated(false)
                    StateStore.setApiToken('')
                    saveConfig(StateStore)
                }

                let message = error.response.data.message

                if (error.response.data.errors) {
                    const errorsArray = Object.entries(error.response.data.errors)
                        .map(error => `${error[1]}`)
                    message = errorsArray.reduce((previous, current) => `${previous}<br />${current}`)
                }


                throw Error(message)
            })
    }

    fetch(params) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        params = qs.stringify(params)
        const requestData = {
            url: `${this._baseUrl}${params ? `?${params}` : ''}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    fetchOne(id) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${id}`,
            method: "GET",
            headers: headers
        }

        return this._makeRequest(requestData)
    }

    save(bodyData) {
        const method = bodyData.id ? "PUT" : "POST"
        const headers = { ...this._headers, 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}${bodyData.id ? `/${bodyData.id}` : ''}`,
            method: method,
            headers: headers,
            data: bodyData
        }

        return this._makeRequest(requestData)
    }

    delete(id) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/${id}`,
            method: "DELETE",
            headers: headers
        }
        return this._makeRequest(requestData)
    }
}


export default Api