import React from 'react'

const Paginate = ({ rowsCount, limit, currentPage, onChangePage }) => {

    const pages = Math.ceil(rowsCount / limit)
    let pagItens = []
    let start = currentPage - 3 < 1 ? 1 : currentPage - 3
    let end = currentPage + 3 > pages ? pages : currentPage + 3

    for (let i = start; i <= end; i++) {
        pagItens.push(
            <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                <button className="page-link" onClick={() => onChangePage(i)}>
                    {i}
                </button>
            </li>
        )
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 || !pages ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="Previous"
                        onClick={() => onChangePage(currentPage - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </button>
                </li>

                {pagItens}

                <li className={`page-item ${currentPage === pages || !pages ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="Next"
                        onClick={() => onChangePage(currentPage + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default Paginate