import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { saveConfig } from "../../services/storage";

import Login from "./index"
import { customToast } from "../../store/ViewStore/Utils";

@inject("stateStore", "loginForm")
@observer
class LoginContainer extends Component {

    constructor(props) {
        super(props);

        this.props.stateStore.setAuthenticated(false);
        this.props.stateStore.setApiToken(this.props.loginForm.accessToken);
        this.props.stateStore.setUser([]);

        saveConfig(this.props.stateStore);
    }

    async onAuth() {
        this.props.loginForm.validateForm();

        if (this.props.loginForm.isValid) {
            await this.props.loginForm.auth();

            if (this.props.loginForm.isAuthenticated) {
                this.props.stateStore.setAuthenticated(true);
                this.props.stateStore.setApiToken(this.props.loginForm.accessToken);
                await this.props.stateStore.requestUser();
                saveConfig(this.props.stateStore);

                this.props.history.push("/");
            } else {
                customToast["error"]('Não foi possível fazer o login. Verifique suas informações e tente novamente.')
            }
        } else {
            customToast["error"]('Preencha todas as informações.')
        }
    }

    onUsernameChange(text) {
        this.props.loginForm.usernameOnChange(text);
    }

    onPasswordChange(text) {
        this.props.loginForm.passwordOnChange(text);
    }

    render() {
        return (
            <Login
                // onChange = {e => this.onchange(e)}
                onAuth={() => this.onAuth()}

                onUsernameChange    = {text => this.onUsernameChange(text)}
                onPasswordChange    = {text => this.onPasswordChange(text)}
                usernameError       = {this.props.loginForm.usernameError}
                passwordError       = {this.props.loginForm.passwordError}
                wasValidated        = {this.props.loginForm.wasValidated}
                isLoading           = {this.props.loginForm.state === 'pending'}
            />
        );
    }
}

export default LoginContainer;
