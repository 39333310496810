import React, { PureComponent } from "react";
import { Form } from "tabler-react";
import StringMask from "string-mask"

class InputMoneyComponent extends PureComponent {
  
  static defaultProps = {
    defaultvalue: '',

  };

  constructor(props) {
    super(props);
    
    //saiu erro do console ...
    if(this.props.defaultValue){
      this.state = {
        value: this.props.defaultValue,
      }
    }else{
      this.state = {
        value: "",
      }
    }
    
  }

  onChange (e) {
    const unmasked = e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
    
    this.setState({
      value: StringMask.apply(unmasked !== '' ? unmasked : '0', '#.##0,00', {reverse: true})
    });

    e.target.value = unmasked / 100;
    this.props.onChange(e);
  }
  
  render() {
    const { value } = this.state;
    
    return (
        <Form.Input
            onChange={e => this.onChange(e)}
            label   = {this.props.label}
            name    = {this.props.name}
            value   = {value}
            type    = 'text'
            invalid = {this.props.invalid}
            feedback = {this.props.feedback}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled} />
    );
  }
}

export default InputMoneyComponent;