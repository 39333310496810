
import { types, flow } from "mobx-state-tree";
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";
import StringMask from "string-mask";
import JsonType from "@viewStore/JsonType";
import { validator } from "../../store/ViewStore/Utils";

const rules = {
    nome: 'required',
    cpf: 'required'
};

const messages = {
    "required.nome": 'O nome deve ser preenchido',
    "required.cpf": 'O cpf deve ser preenchido'
};

const LotacaoModel = types.model({

    // Identificador da lotação
    indice: types.maybe(types.integer),

    id: types.maybe(types.string),

    categoria_id: types.maybe(types.string),
    categoriaSelect: types.optional(JsonType, []),

    origem_id: types.maybe(types.string),
    origemSelect: types.optional(JsonType, []),

    ch: types.optional(types.string, ""),
    ch_suplementada: types.optional(types.string, ""),
    vencimento_base: types.optional(types.string, ""),
    matricula: types.optional(types.string, ""),
    trabalha_meio_periodo: types.optional(types.boolean, false),

    cargo_id: types.maybe(types.string),
    cargoSelect: types.optional(JsonType, []),

    isEditMode: types.optional(types.boolean, false),
    disableVencimentoBaseField: types.optional(types.boolean, true),
    disableCargoField: types.optional(types.boolean, true),
    disableCHField: types.optional(types.boolean, true),
    disableCHSField: types.optional(types.boolean, true),
    disableTrabalhaMeioPeriodoCheck: types.optional(types.boolean, true),

    origem_idError: types.optional(types.string, ""),
    categoria_idError: types.optional(types.string, ""),
    vencimento_baseError: types.optional(types.string, ""),
    cargo_idError: types.optional(types.string, ""),
    matriculaError: types.optional(types.string, ""),
    chError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false)

})
    .views(self => {
        return {
            get vencimentoBase() {
                let unmasked = self.vencimento_base.replace(/[^0-9]/g, '').replace(/^0+/, '')
                return StringMask.apply(unmasked, '#.##0,00', { reverse: true })
            },

            get cargaHoraria() {
                return self.ch.replace(/[^0-9]/g, '')
            },

            get cargaHorariaSuplementada() {
                return self.ch_suplementada.replace(/[^0-9]/g, '')
            }
        }
    })
    .actions(self => ({

        setOrigemSelect(origens) {
            self.origemSelect = origens
        },

        setCategoriaSelect(categorias) {
            self.categoriaSelect = categorias
        },

        setCargoSelect(cargo) {
            self.cargoSelect = cargo
        },

        onChangeOrigem(value) {
            self.origem_id = value
        },

        onChangeCategoria(value) {
            self.vencimento_baseError = ""
            self.cargo_idError = ""
            self.cargo_id = ""
            self.vencimento_base = ""

            const categoria = self.categoriaSelect.filter(categoria => categoria.id === value)[0]
            self.setupFields(categoria)

            self.categoria_id = value ? value : undefined
        },

        onChangeColaborador(value) {
            self.colaborador_id = value ? value : undefined
        },

        onChangeVencimentoBase(value) {
            const unmasked = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
            const number = (unmasked / 100).toFixed(2).toString()
            self.vencimento_base = number
        },

        onChangeMatricula(value) {
            self.matricula = value
        },

        onChangeTrabalhaMeioPeriodo(value) {
            self.trabalha_meio_periodo = value
        },

        onCancelEditLotacao() {
            self.isEditMode = false
            self.setupFields()
        },

        onChangeCargaHoraria(value) {
            self.ch = value
        },

        onChangeCargaHorariaSuplementada(value) {
            self.ch_suplementada = value
        },

        validate() {
            const rules = {
                matricula: 'required',
                categoria_id: 'required',
                origem_id: 'required'
            }

            const messages = {
                "required.origem_id": 'Selecione uma origem',
                "required.matricula": 'O matrícula deve ser preenchida',
                "required.categoria_id": 'Selecione uma categoria'
            }

            if (!self.disableCHField) {
                rules["ch"] = "required"
                messages["required.ch"] = "A carga horária deve ser preenchida"
            }

            if (!self.disableVencimentoBaseField) {
                rules["vencimento_base"] = "required"
                messages["required.vencimento_base"] = "Informe o vencimento"
            }

            if (!self.disableCargoField) {
                rules.cargo_id = 'required'
                messages["required.cargo_id"] = "Informe o cargo"
            }
            validator(self, rules, messages)
        },

        getInfoAdicionais(lotacao) {
            let infoAdicionais = '-'
            if (lotacao.vencimento_base) {
                infoAdicionais = `Vencimento: R$ ${(+lotacao.vencimento_base).toLocaleString()}`
            }

            if (lotacao.cargo_id) {
                const simbolo = self.cargoSelect.find(item => item.id === lotacao.cargo_id).simbolo
                infoAdicionais = `Simbologia: ${simbolo}`
            }
            return infoAdicionais
        },

        setupFields(categoria) {

            // reseta todos os campos
            self.disableTrabalhaMeioPeriodoCheck = true
            self.disableCHField = true
            self.disableCHSField = true
            self.disableVencimentoBaseField = true
            self.disableCargoField = true

            self.trabalha_meio_periodo = false
            self.ch = ''
            self.ch_suplementada = ''
            self.vencimento_base = ''
            self.cargo_id = ''

            if (categoria) {
                const {
                    exibe_campo_vencimento_base,
                    exibe_campo_cargo,
                    exibe_campos_agente_comunitario_saude
                } = categoria

                // configura campos para categoria acs
                if (exibe_campos_agente_comunitario_saude) {
                    self.disableTrabalhaMeioPeriodoCheck = false
                } else {
                    self.disableCHField = false
                    self.disableCHSField = false
                    self.disableTrabalhaMeioPeriodoCheck = true
                    self.disableVencimentoBaseField = !exibe_campo_vencimento_base
                    self.disableCargoField = !exibe_campo_cargo
                }
            }
        },

        clear(
            clearSelectCategoria = true,
            clearSelectOrigem = true,
            clearMatricula = true,
            clearTrabalhaMeioPeriodo = true
        ) {
            self.indice = undefined
            self.id = ""
            self.origem_id = ""
            self.categoria_id = ""
            self.cargo_id = ""
            self.vencimento_base = ""
            clearMatricula && (self.matricula = "")
            self.isEditMode = false
            self.ch = ""
            self.ch_suplementada = ""
            clearTrabalhaMeioPeriodo && (self.trabalha_meio_periodo = false)

            // Selects
            clearSelectCategoria && (self.categoriaSelect = [])
            clearSelectOrigem && (self.cargoSelect = [])

            self.matriculaError = ""
            self.origem_idError = ""
            self.categoria_idError = ""
            self.vencimento_baseError = ""
            self.cargo_idError = ""
            self.chError = ""
        }
    }))

const Form = types.model(
    {
        id: types.optional(types.string, ""),
        nome: types.optional(types.string, ""),
        cpf: types.optional(types.string, ""),
        cns: types.optional(types.string, ""),
        lotacoes: types.array(JsonType, []),

        formLotacao: types.optional(LotacaoModel, {}),

        nomeError: types.optional(types.string, ""),
        cpfError: types.optional(types.string, ""),

        isValid: types.optional(types.boolean, false),
        wasValidated: types.optional(types.boolean, false),
        state: types.enumeration("State", ["pending", "done", "error"]),
        stateMessage: types.optional(types.string, "")
    })
    .views(self => {
        return {
            get cpfMask() {
                return StringMask.apply(self.cpf, '000.000.000-00')
            },

            get categoriaLabel() {
                if (self.formLotacao.categoria_id)
                    return self.formLotacao.categoriaSelect.find(item => item.id === self.formLotacao.categoria_id).descricao

                return '-'
            },

            get cargoLabel() {
                if (self.formLotacao.cargo_id)
                    return self.formLotacao.cargoSelect.find(item => item.id === self.formLotacao.cargo_id).simbolo

                return '-'
            },

            get origemLabel() {
                if (self.formLotacao.origem_id)
                    return self.formLotacao.origemSelect.find(item => item.id === self.formLotacao.origem_id).nome

                return '-'
            },
        }
    })
    .actions(self => ({

        onChangeNome(value) {
            self.nome = value;
            self.validate('nome');
        },

        onChangeCpf(value) {
            self.cpf = value;
            self.validate('cpf');
        },

        onChangeCargo(value) {
            self.formLotacao.cargo_id = value ? value : undefined
        },

        onChangeCns(value) {
            self.cns = value
        },

        onEditLotacao(idx) {
            const {
                id,
                origem_id,
                categoria_id,
                cargo_id,
                vencimento_base,
                matricula,
                ch,
                ch_suplementada,
                trabalha_meio_periodo
            } = self.lotacoes[idx]

            self.formLotacao.clear(false, false)
            self.formLotacao.origem_id = origem_id
            self.formLotacao.onChangeCategoria(categoria_id)
            self.formLotacao.isEditMode = true

            self.formLotacao.id = id || ""
            self.formLotacao.cargo_id = cargo_id || ""
            self.formLotacao.vencimento_base = vencimento_base || ""
            self.formLotacao.matricula = matricula
            self.formLotacao.ch = ch ? ch.toString() : ""
            self.formLotacao.ch_suplementada = ch_suplementada ? ch_suplementada.toString() : ""
            self.formLotacao.trabalha_meio_periodo = !!trabalha_meio_periodo
            self.formLotacao.indice = idx
        },

        addLotacao() {
            self.formLotacao.validate()

            if (self.formLotacao.isValid) {
                // Destructuring
                let {
                    indice,
                    id,
                    matricula,
                    cargo_id,
                    origem_id,
                    categoria_id,
                    vencimento_base,
                    ch,
                    ch_suplementada,
                    trabalha_meio_periodo
                } = self.formLotacao

                let categoriaLabel = self.categoriaLabel
                let origemLabel = self.origemLabel
                let infoAdicionais = self.formLotacao.getInfoAdicionais({
                    vencimento_base, cargo_id
                })

                const lotacao = { id, cargo_id, origemLabel, origem_id, categoria_id, categoriaLabel, matricula, vencimento_base, infoAdicionais, ch, ch_suplementada, trabalha_meio_periodo }
                if (Number.isInteger(indice)) {
                    self.lotacoes[indice] = lotacao
                } else {
                    self.lotacoes.push(lotacao)
                }
                self.formLotacao.clear()
            }
        },

        removeLotacao(idx) {
            self.lotacoes.splice(idx, 1)
        },

        changeState(state) {
            self.state = state
        },

        clear() {
            self.id = "";
            self.nome = "";
            self.cpf = "";
            self.cns = "";
            self.lotacoes = [];

            self.formLotacao.clear()

            self.nomeError = "";
            self.cpfError = "";

            self.isValid = false;
            self.wasValidated = false;
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        validate(field) {
            validator(self, rules, messages, field)
        },

        fetch: flow(function* fetch(id) {
            self.clear();
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.fetchOne(id);

                if (response.data) {
                    self.id = response.data.id;
                    self.nome = response.data.nome;
                    self.cpf = response.data.cpf;
                    self.cns = response.data.cns || "";

                    const lotacoes = response.data.lotacoes.map(lotacao => {
                        // Destructuring
                        let {
                            id,
                            origem_id,
                            cargo_id,
                            categoria_id,
                            vencimento_base,
                            matricula,
                            ch,
                            ch_suplementada,
                            trabalha_meio_periodo
                        } = lotacao

                        const categoriaLabel = lotacao.categoria.descricao
                        const origemLabel = lotacao.origem.nome
                        let infoAdicionais = '-'

                        if (cargo_id) {
                            infoAdicionais = `Simbologia: ${lotacao.cargo.simbolo}`
                        }

                        if (vencimento_base) {
                            infoAdicionais = `Vencimento: R$ ${(+lotacao.vencimento_base).toLocaleString()}`
                        }

                        return {
                            id,
                            origem_id,
                            origemLabel,
                            cargo_id,
                            categoria_id,
                            vencimento_base,
                            matricula,
                            categoriaLabel,
                            infoAdicionais,
                            ch,
                            ch_suplementada,
                            trabalha_meio_periodo
                        }
                    })
                    self.lotacoes = lotacoes
                }

                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.save({
                    id: self.id,
                    nome: self.nome,
                    cpf: self.cpf,
                    cns: self.cns,
                    lotacoes: self.lotacoes
                });

                self.state = "done"
                self.stateMessage = response.message
                self.id = response.data.id
                // Recarregando as lotações

                const lotacoes = response.data.lotacoes.map(lotacao => {
                    // Destructuring
                    let {
                        id,
                        origem_id,
                        cargo_id,
                        categoria_id,
                        vencimento_base,
                        matricula,
                        ch,
                        ch_suplementada,
                        trabalha_meio_periodo
                    } = lotacao

                    const categoriaLabel = lotacao.categoria.descricao
                    const origemLabel = lotacao.origem.nome
                    let infoAdicionais = '-'

                    if (cargo_id) {
                        infoAdicionais = `Simbologia: ${lotacao.cargo.simbolo}`
                    }

                    if (vencimento_base) {
                        infoAdicionais = `Vencimento: R$ ${(+lotacao.vencimento_base).toLocaleString()}`
                    }

                    return {
                        id,
                        origem_id,
                        origemLabel,
                        cargo_id,
                        categoria_id,
                        vencimento_base,
                        matricula,
                        categoriaLabel,
                        infoAdicionais,
                        ch,
                        ch_suplementada,
                        trabalha_meio_periodo
                    }
                })

                self.lotacoes = lotacoes

            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }));

export default Form.create({ state: 'done' });