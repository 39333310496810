import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import Exportacao from './index'
import Grid from "./grid";

@inject("stateStore", "exportacaoGridStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.stateStore = this.props.stateStore
        this.gridStore = this.props.exportacaoGridStore
        this._gridStore.clearState()
    }

    showForm(id) {
        super.showForm(id)
    }

    render() {
        const gridComponent = (
            <Grid
                isLoading={this._gridStore.state === 'pending'}
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        );
        return (
            <Exportacao
                component={gridComponent}
            />
        );
    }
}

export default Container;