import Api from '../../services/api_'

class Service extends Api {
    load(bodyData) {
        const headers = {
            ...this._headers,
            'Authorization': `Bearer ${this._token}`,
            'Content-Type': 'multipart/form-data'
        }
        const requestData = {
            url: `${this._baseUrl}/load`,
            method: "POST",
            headers: headers,
            data: bodyData
        }
        return this._makeRequest(requestData)
    }

    import(bodyData) {
        const headers = {
            ...this._headers,
            'Authorization': `Bearer ${this._token}`,
            'Content-Type': 'application/json'
        }
        const requestData = {
            url: `${this._baseUrl}/import`,
            method: "POST",
            headers: headers,
            data: bodyData
        }
        return this._makeRequest(requestData)
    }

    download() {
        const headers = {
            ...this._headers,
            'Accept': 'application/json',
            'Authorization': `Bearer ${this._token}`
        }
        const requestData = {
            url: `${this._baseUrl}/download`,
            method: "POST",
            headers: headers,
            responseType: 'arraybuffer'
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('importacao')