import React, { Component } from "react";
import { ToastContainer } from 'react-toastify'

import { Button, Form, Dimmer} from "tabler-react";


class Login extends Component{

    render(){

        return(
            <div className="limiter">
                <div className="container-login">
                    <div className="wrap-login">
                        <Dimmer active={this.props.isLoading} loader>
                            <div className="login-form">
                                <span className="login-form-title">
                                    Faça o login para continuar
                                </span>

                                <Form.Input
                                    name="username"
                                    placeholder="email@email.com.br"
                                    className="form-control-xl mb-5"
                                    
                                    cross={ !this.props.usernameError === '' && this.props.wasValidated  }
                                    tick={ this.props.usernameError === '' && this.props.wasValidated}
                                    
                                    onChange={e =>this.props.onUsernameChange(e.target.value)}
                                />
                            
                                <Form.Input
                                    name="password"
                                    type="password"
                                    placeholder="*******"
                                    className="form-control-xl mb-7"
                                    
                                    cross={ !this.props.passwordError === '' && this.props.wasValidated }
                                    tick={ this.props.passwordError === '' && this.props.wasValidated }

                                    onChange={e =>this.props.onPasswordChange(e.target.value)}
                                />

                                
                                
                            

                                <Button 
                                    block 
                                    color="primary"
                                    type = "button"
                                    onClick={() => this.props.onAuth()}
                                    className="btn-lg"
                                    >LOGIN
                                </Button>
                            </div>
                        </Dimmer>
                        <div className="login-more" style={{ backgroundImage: "url('bg-login.jpg')" }}>
                        </div>
                        <ToastContainer hideProgressBar />
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Login;