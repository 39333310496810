import * as React from "react";
import { Page, Card, Button, Form, Grid } from "tabler-react";
import { observer } from "mobx-react";
import InputMoney from '../../components/InputMoneyComponent'

@observer
class PageForm extends React.Component {

    render() {
        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Cargo
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <Button color="primary" className="ml-auto" type="button" onClick={e => this.props.onSave()}>
                                    {this.props.store.id ? 'Atualizar' : 'Salvar'}
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row>

                        <Grid.Col md={3}>
                            <Form.Input
                                label='Simbologia'
                                type='text'
                                defaultValue={this.props.store.simbolo}
                                onChange={(e) => this.props.store.onChangeSimbolo(e.target.value)}
                                placeholder='Simbologia'

                                feedback={this.props.store.wasValidated ? this.props.store.simboloError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.simboloError} />
                        </Grid.Col>

                        <Grid.Col md={3}>
                            <InputMoney
                                label='Remuneração'
                                type='text'
                                placeholder='Remuneração'
                                defaultValue={this.props.store.remuneracaoMask}
                                onChange={(e) => this.props.store.onChangeRemuneracao(e.target.value)}
                                feedback={this.props.store.wasValidated ? this.props.store.remuneracaoError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.remuneracaoError} />
                        </Grid.Col>
                    </Grid.Row>

                </Page.Card>
            </>
        );
    }
}

export default PageForm;