import * as React from "react"
import { observer } from "mobx-react"
import { Page, Card, Table, Icon, Grid, Form, Dimmer } from "tabler-react"

@observer
class PageGrid extends React.Component {

    render() {

        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {`${row.nome}`}
                </Table.Col>
                <Table.Col alignContent="center">
                    <Icon link name="download" onClick={() => this.props.store.download(row.codigo_sepog, row.decreto_id)} />
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={2} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        )

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        Exportação
                    </Page.Title>
                </Page.Header>

                <Card body={
                    <Grid.Row>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col md={2}>
                                    <Form.Group>
                                        <Form.Input
                                            type="text"
                                            label="Competência"
                                            value={this.props.store.getCompetencia}
                                            onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                            maxLength="7"
                                            placeholder='AAAA/MM'
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            {/* <Grid.Row>
                                <Grid.Col width={2}>
                                    <Button
                                        type='button'
                                        value='button'
                                        // onClick={() => this.props.onChangePage(1)}
                                        color="cyan">
                                        Pesquisar
                                    </Button>
                                </Grid.Col>
                            </Grid.Row> */}
                        </Grid.Col>
                    </Grid.Row>
                } >
                </Card>
                <Dimmer active={this.props.isLoading} loader>
                    <Card>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Origem</Table.ColHeader>
                                    <Table.ColHeader alignContent="center">Arquivo</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rows.length > 0 ? rows : noRows}
                                <Table.Row>

                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Card>
                </Dimmer>
            </Page.Content>
        )
    }
}

export default PageGrid