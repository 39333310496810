import React from 'react'
import { Badge } from "tabler-react"

export default function StatusRemessa(props) {

    const color = {
        C: 'success',
        P: 'warning',
        E: 'danger'
    }

    return (
        <Badge color={color[props.status] ?? 'secondary'} className="p-1">
            {props.descricao}
        </Badge>
    )
}