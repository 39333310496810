import Api from '../../services/api_'

class Service extends Api {
    download(codigo_sepog, competencia, decreto_id) {
        const headers = {
            ...this._headers,
            'Accept': 'application/json',
            'Authorization': `Bearer ${this._token}`
        }
        const requestData = {
            url: `${this._baseUrl}/download`,
            method: "POST",
            headers: headers,
            data: { codigo_sepog, competencia, decreto_id }
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('exportacao')