import * as React from "react"
import { Page, Card, Button, Table, Grid, Badge, Icon, Form } from "tabler-react"
import Paginate from "../../components/Paginate"
import StringMask from "string-mask"
import { observer } from "mobx-react"
import Select from 'react-select'
import If from '../../components/if'

@observer
class PageGrid extends React.Component {

    render() {

        const selectOrigem = (
            <Select
                closeMenuOnSelect={true}
                placeholder="Selecione uma origem"
                options={this.props.store.origemSelect}
                isClearable={true}
                onChange={options => this.props.store.onChangeOrigem(options)}
                value={this.props.store.origem}
            >
            </Select>
        )

        const rows = this.props.store.rows.map((row, i) => (
            <Table.Row key={i} className="myList">
                <Table.Col>
                    {StringMask.apply(row.competencia, '0000/00')}
                </Table.Col>
                <Table.Col>
                    {row.origem_nome}
                </Table.Col>
                <Table.Col>
                    <Badge color={row.calculado ? "success" : "secondary"} className="p-1">
                        {row.calculado ? 'Calculado' : 'Não calculado'}
                    </Badge>
                </Table.Col>
                <Table.Col>
                    <Badge color={row.retificado ? 'primary' : 'secondary'} >
                        {row.retificado ? 'SIM' : 'NÃO'}
                        {row.numero_retificacao > 0 ? ' ('+row.numero_retificacao +'ª RETIFICAÇÃO)' : ''}
                    </Badge>
                </Table.Col>
                <Table.Col alignContent="center">
                    <If test={row.calculado}>
                        <Icon link name="file-text" onClick={() => this.props.showReport(row.report_name, row.origem_id, row.competencia)} />
                    </If>
                    <If test={row.executa_calculo}>
                        <Icon link name="play-circle" onClick={() => this.props.calculateRemessa(row.remessa_id)} />
                    </If>
                    <If test={row.executa_calculo && row.calculado}>
                        <Icon link name="rotate-ccw" onClick={() => this.props.revertRemessa(row.remessa_id)} />
                    </If>
                    <Icon link name={row.calculado ? 'eye' : 'edit'} onClick={() => this.props.showForm({
                        competencia: row.competencia,
                        origem_id: row.origem_id,
                        remessa_id: row.remessa_id || null,
                        retificado: row.retificado,
                        calculado: row.calculado
                    })} />
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={6} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        )

        return (
            <Page.Content>
                <Page.Header>
                    <Page.Title>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                Lançamentos
                                </Grid.Col>
                            <Grid.Col md={2}>

                                <Button
                                    onClick={() => this.props.showForm()}
                                    color="cyan"
                                    icon="plus">
                                    Novo registro
                                </Button>

                            </Grid.Col>

                        </Grid.Row>
                    </Page.Title>
                </Page.Header>

                <Card
                    body={(
                        <Grid.Row>
                            <Grid.Col>
                                <Grid.Row>
                                    <Grid.Col md={2}>
                                        <Form.Group>
                                            <Form.Input
                                                label='Competência'
                                                type='text'
                                                value={this.props.store.getCompetencia}
                                                onChange={e => this.props.store.onChangeCompetencia(e.target.value)}
                                                maxLength="7"
                                                placeholder='AAAA/MM'
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={2}>
                                        <Form.Select
                                            label="Status Remessa"
                                            value={this.props.store.status_remessa}
                                            onChange={e => this.props.store.onChangeStatusRemessa(e.target.value)}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="true">Calculado</option>
                                            <option value="false">Não calculado</option>
                                        </Form.Select>
                                    </Grid.Col>
                                    <Grid.Col md={2}>
                                        <Form.Select
                                            label="Retificado"
                                            value={this.props.store.retificado}
                                            onChange={e => this.props.store.onChangeRetificado(e.target.value)}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="true">SIM</option>
                                            <option value="false">NÃO</option>
                                        </Form.Select>
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <Form.Label>Origem</Form.Label>
                                        {selectOrigem}
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col width={2}>
                                        <Button
                                            type='button'
                                            value='button'
                                            onClick={() => this.props.onChangePage(1)}
                                            color="cyan"
                                        >
                                            Pesquisar
                                        </Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                    )}>

                </Card>
                <Card>
                    <Table
                        responsive
                        highlightRowOnHover
                        hasOutline
                        verticalAlign="center"
                        cards
                        className="text-nowrap"

                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.ColHeader>Competência</Table.ColHeader>
                                <Table.ColHeader>Origem</Table.ColHeader>
                                <Table.ColHeader>Status remessa</Table.ColHeader>
                                <Table.ColHeader>Retificado</Table.ColHeader>
                                <Table.ColHeader alignContent="center">Ações</Table.ColHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.length > 0 ? rows : noRows}
                            <Table.Row>
                                <Table.Col colSpan={6}>
                                    <Paginate
                                        rowsCount={this.props.store.rowsCount}
                                        limit={this.props.store.limit}
                                        currentPage={this.props.store.page}
                                        onChangePage={this.props.onChangePage} />
                                </Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </Card>
            </Page.Content>
        )
    }
}

export default PageGrid