import Api from '../../services/api_'
import qs from 'querystring'

class Service extends Api {
    // Serviço que obtém o grupo de lançamentos
    getOne(params) {
        const headers = { ...this._headers, 'Accept': 'application/json', 'Authorization': `Bearer ${this._token}` }
        params = qs.stringify(params)
        const requestData = {
            url: `${this._baseUrl}/show${params ? `?${params}` : ''}`,
            method: "GET",
            headers: headers
        }
        return this._makeRequest(requestData)
    }

    obterProducaoMediaAcs(colaboradorId, competenciaRef) {
        const headers = { ...this._headers, 'Authorization': `Bearer ${this._token}` }
        const requestData = {
            url: `${this._baseUrl}/calcular-media-producao`,
            method: 'POST',
            headers: headers,
            data: { colaboradorId, competenciaRef }
        }
        return this._makeRequest(requestData)
    }
}

export default new Service('lancamentos')