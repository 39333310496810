import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import StringMask from "string-mask"
import JsonType from "@viewStore/JsonType"
import { validator, filterDigits } from "../../store/ViewStore/Utils"

const rules = {
    competencia: 'required',
}

const messages = {
    "required.competencia": 'A competência deve ser preenchida',
}

const FormLancamento = types.model({
    id: types.maybeNull(types.string),
    lotacao_id: types.maybeNull(types.string),
    remessa_id: types.maybeNull(types.string),
    categoria_id: types.string,
    valor_producao: types.maybeNull(types.string),
    valor_gip_individual: types.maybeNull(types.string),
    valor_gip_coletivo: types.maybeNull(types.string),
    valor_gip_total: types.maybeNull(types.string),
    valor_vb: types.maybeNull(types.string),
    valor_cargo: types.maybeNull(types.string),
    percentual_desconto: types.maybeNull(types.string),
    valor_gip_compensada: types.maybeNull(types.string),
    matricula: types.maybeNull(types.string),
    lotacao_snapshot: types.maybeNull(JsonType),

    // campos acs
    qtde_faltas_n_justificadas: types.optional(types.union(types.string, types.integer, types.null), ''),
    qtde_cadastros_realizados: types.optional(types.union(types.string, types.integer, types.null), ''),
    qtde_visitas: types.optional(types.union(types.string, types.integer, types.null), ''),
    qtde_visitas_cresca: types.optional(types.union(types.string, types.integer, types.null), ''),
    qtde_grupos: types.optional(types.union(types.string, types.integer, types.null), ''),
    qtde_folgas: types.optional(types.union(types.string, types.integer, types.null), ''),
    valor_producao_media: types.optional(types.union(types.string, types.integer, types.null), ''),
    afastamento: types.optional(types.union(types.enumeration('afastamento', ['licenca', 'ferias', '']), types.null), ''),

    visible: types.optional(types.boolean, true),
    situacao_lotacao: types.optional(JsonType, {
        updated: false,
        badgeColor: 'secondary',
        badgeText: 'Sem alteração',
        snapshot_atual: null,
        snapshot_anterior: null,
        categoria_atual: null,
        categoria_anterior: null
    }),

    colaborador: types.optional(JsonType, {}),
    categoria: types.optional(JsonType, {}),
    cargo: types.optional(JsonType, {}),
})
    .views(self => {
        return {
            get valorProducao() {
                if (self.valor_producao) {
                    let unmasked = self.valor_producao.replace(/[^0-9]/g, '').replace(/^0+/, '')
                    return StringMask.apply(unmasked, '#.##0,00', { reverse: true })
                }
                return ""
            },

            get valorCargo() {
                return Number(self.valor_cargo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            },

            get valorCompensado() {
                if (!self.valor_gip_compensada) return self.valor_gip_compensada
                return Number(self.valor_gip_compensada).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
            },

            get valorGipTotal() {
                if (!self.valor_gip_total) return self.valor_gip_total
                return Number(self.valor_gip_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            },

            get percentualDesconto() {
                if (self.percentual_desconto) {
                    let unmasked = self.percentual_desconto.replace(/[^0-9]/g, '').replace(/^0+/, '')
                    return StringMask.apply(unmasked, '#0,00', { reverse: true })
                }
                return ""
            },

            get valorVencimentoBase() {
                return Number(self.valor_vb).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            },

            get cpf() {
                return StringMask.apply(self.colaborador.cpf, '000.000.000-00')
            }
        }
    })
    .actions(self => ({
        onChangeValorProducao(value) {
            const unmasked = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
            const number = unmasked ? (unmasked / 100).toFixed(2).toString() : ""
            self.valor_producao = number
        },

        onChangePercentualDesconto(value) {
            const unmasked = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
            let desconto = unmasked ? (unmasked / 100).toFixed(2).toString() : ""
            desconto = +desconto > 100 ? self.percentual_desconto : desconto
            self.percentual_desconto = desconto
        },

        setSituacao(situacao_lotacao) {
            self.situacao_lotacao = situacao_lotacao
        },

        setCategoria(categoria) {
            self.categoria = categoria
        },

        setQtdeFaltasNJustificadas(value) {
            self.qtde_faltas_n_justificadas = +filterDigits(value, self.qtde_faltas_n_justificadas)
        },

        setQtdeCadastrosRealizados(value) {
            self.qtde_cadastros_realizados = +filterDigits(value, self.qtde_cadastros_realizados)
        },

        setQtdeVisitas(value) {
            self.qtde_visitas = +filterDigits(value, self.qtde_visitas)
        },

        setQtdeVisitasCresca(value) {
            self.qtde_visitas_cresca = +filterDigits(value, self.qtde_visitas_cresca)
        },

        setQtdeGrupos(value) {
            self.qtde_grupos = +filterDigits(value, self.qtde_grupos)
        },

        setQtdeFolgas(value) {
            self.qtde_folgas = +filterDigits(value, self.qtde_folgas)
        },

        setProducaoMedia(value) {
            self.valor_producao_media = +filterDigits(value, self.valor_producao_media)
        },

        onChangeAfastamento(value, producaoMedia) {
            self.afastamento = value

            self.qtde_faltas_n_justificadas = value ? '' : 0
            self.qtde_visitas = value ? '' : 0
            self.qtde_visitas_cresca = value ? '' : 0
            self.qtde_grupos = value ? '' : 0
            self.qtde_folgas = value ? '' : 0
            self.valor_producao_media = value ? '0' : ''
            self.valor_producao_media = producaoMedia ? producaoMedia : (value ? '0' : '')
        }
    }))

const Form = types.model({
    isEdit: types.optional(types.boolean, false),
    competencia: types.optional(types.string, ""),
    retificado: types.optional(types.boolean, false),
    origem: types.optional(JsonType, null),
    origemDisabled: types.optional(types.boolean, true),
    origemSelect: types.optional(JsonType, []),
    remessaAnteriorId: types.maybeNull(types.string),
    lancamentos: types.array(FormLancamento, {}),
    filtroLancamentos: types.optional(types.string, ""),
    disableBtnSalvar: types.optional(types.boolean, false),
    matriculaSearch: types.optional(types.string, ""),

    competenciaError: types.optional(types.string, ""),
    processado: types.optional(types.boolean, false),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get getCompetencia() {
                return StringMask.apply(self.competencia, '####/##')
            },

            get lancamentosByCategoria() {

                // Agrupando por categoria
                let group = self.lancamentos.reduce((r, current) => {
                    r[current.categoria.id] = [...r[current.categoria.id] || [], current]
                    return r
                }, {})

                // Ordenando por nome do colaborador
                for (const key in group) {
                    group[key].sort((l1, l2) => {
                        if (l1.colaborador.nome < l2.colaborador.nome) return -1
                        if (l1.colaborador.nome > l2.colaborador.nome) return 1
                        return 0
                    })
                }

                return group
            },

            get getLancamentos() {
                return self.lancamentos.map(item => {
                    const lancamento = { ...item }
                    lancamento.competencia = self.competencia
                    lancamento.retificado = self.retificado
                    delete lancamento.categoria
                    delete lancamento.colaborador
                    delete lancamento.cargo
                    return lancamento
                })
            },
        }
    })
    .actions(self => ({

        onChangeFiltroLancamento(value) {
            self.filtroLancamentos = value
            const lancamentosPesquisados = self.lancamentos.map(lancamento => {
                lancamento.visible = false
                value = value.toLowerCase()
                const nomeColaborador = lancamento.colaborador.nome.toLowerCase()
                const cpf = lancamento.colaborador.cpf.replace(/[^\d]/g, '')
                const matricula = lancamento.matricula
                if (
                    nomeColaborador.indexOf(value) !== -1
                    || cpf.indexOf(value) !== -1
                    || matricula.indexOf(value) !== -1
                ) {
                    lancamento.visible = true
                }

                return lancamento
            })
            self.lancamentos = lancamentosPesquisados
        },

        addLancamentos(lancamentos) {
            self.lancamentos = lancamentos
        },

        addLancamento(lancamento) {
            self.lancamentos.push(lancamento)
        },

        updateLancamentos(lancamentos) {
            self.lancamentos.forEach(l => {
                const lancamento = lancamentos.filter(lan => lan.lotacao.matricula === l.matricula)
                if (lancamento.length) {
                    l.percentual_desconto = lancamento[0].percentual_desconto
                    l.valor_producao = lancamento[0].valor_producao
                }
            })
        },

        removerLancamento(lotacaoID) {
            self.lancamentos.forEach((l, idx) => {
                if (l.lotacao_id === lotacaoID) {
                    self.lancamentos.splice(idx, 1)
                    return false
                }
            })
        },

        changeLotacaoSnapshotLancamento(lotacaoID, mudarParaAnterior) {

            const lancamento = self.lancamentos.find(l => l.lotacao_id === lotacaoID)

            let snapshot = mudarParaAnterior
                ? lancamento.situacao_lotacao.snapshot_anterior
                : lancamento.situacao_lotacao.snapshot_atual

            const { vencimento_base, cargo_remuneracao, categoria_id } = JSON.parse(snapshot)

            lancamento.valor_vb = vencimento_base
            lancamento.valor_cargo = cargo_remuneracao
            lancamento.categoria_id = categoria_id
            lancamento.valor_producao = null
            lancamento.percentual_desconto = null

            lancamento.categoria = mudarParaAnterior
                ? lancamento.situacao_lotacao.categoria_anterior
                : lancamento.situacao_lotacao.categoria_atual

            lancamento.lotacao_snapshot = snapshot

            lancamento.setSituacao({
                ...lancamento.situacao_lotacao,
                badgeColor: mudarParaAnterior ? 'info' : 'warning',
                badgeText: mudarParaAnterior ? 'Remessa anterior' : 'Lotação atualizada'
            })
        },

        clearLancamentos() {
            self.lancamentos = []
        },

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
            self.validate('competencia')
        },

        onChangeRetificado(isChecked) {
            self.retificado = isChecked
        },

        onChangeOrigem(value) {
            self.origem = value
        },

        onChangeMatricula(value) {
            self.matriculaSearch = value.trim()
        },

        setOrigemSelect(origens) {
            self.origemSelect = origens
        },

        setDisableBtnSalvar(isDisabled) {
            self.disableBtnSalvar = isDisabled
        },

        changeState(value) {
            self.state = value
        },

        validate(field) {
            validator(self, rules, messages, field)
        },

        setIsEdit(value) {
            self.isEdit = value
        },

        setProcessado(value) {
            self.processado = value
        },

        setRemessaAnteriorId(value) {
            self.remessaAnteriorId = value
        },

        clear() {
            self.isEdit = false
            self.competencia = ""
            self.retificado = false
            self.origem = null
            self.origemDisabled = true
            self.disableBtnSalvar = false
            self.matriculaSearch = ""
            self.remessaAnteriorId = null

            self.lancamentos = []
            self.filtroLancamentos = ""

            self.competenciaError = ""

            self.isValid = false
            self.wasValidated = false
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetch: flow(function* fetch(
            competencia,
            origemID,
            retificado,
            remessa_id,
            remessa_id_is_null
        ) {
            self.clear()
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const response = yield api.getOne({
                    competencia,
                    origemID,
                    retificado,
                    remessa_id,
                    remessa_id_is_null
                })
                if (response.data) {
                    // Recarregando a lista de Lançamentos
                    const rows = response.data.rows
                    Object.entries(rows).forEach(([competencia, origem]) => {
                        Object.entries(origem).forEach(([origemNome, lancamentos]) => {
                            const lancamentosForm = lancamentos.map(lancamento => {
                                return {
                                    ...lancamento,
                                    matricula: lancamento.lotacao.matricula,
                                    colaborador: { ...lancamento.lotacao.colaborador },
                                    categoria: { ...lancamento.categoria },
                                    cargo: { ...lancamento.lotacao.cargo }
                                }
                            })
                            self.addLancamentos(lancamentosForm)
                        })
                    })
                }
                self.isEdit = true
                self.retificado = retificado
                self.competencia = competencia
                if (self.state !== 'pending')
                    self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const response = yield api.save({
                    competencia: self.competencia,
                    retificado: self.retificado,
                    origem: self.origem.value,
                    lancamentos: self.getLancamentos
                })

                if (response.data) {
                    // Pegando os ids dos lancamentos
                    response.data.forEach(({ id, lotacao_id }) => {
                        const lan = self.lancamentos.find(l => l.lotacao_id === lotacao_id)
                        if (lan)
                            lan.id = id
                    })
                }
                self.isEdit = true
                self.state = "done"
                self.stateMessage = response.message
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }))
export default Form.create({ state: 'done' })