// import axios from 'axios';
// import qs from 'querystring';

const oauth = function (host, params) {
  const url = `${host}/oauth/token`;
  
  let details = {
      grant_type : 'password',
      client_id : '1',
      client_secret: 'ZwqTCPwf6EsY7738oLOYLnUZLZXZbBVLdiYK8Ozw',
      username: params.username,
      password: params.password,
  };

  let formBody = [];
  for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  const fetchData = {
      method: "POST",
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          
      },
      body: formBody
  };

  return fetch(url, fetchData).then(response => response.json());
}

const userInfo = function (host, params) {
  const url = `${host}/api/usuario`;

  const fetchData = {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${params.token}`
      },
      // body: qs.stringify(requestBody)
  };
  
  // return axios.get(url, qs.stringify(requestBody), config);
  return fetch(url, fetchData).then(response => response.json());
}

export default {
  oauth,
  userInfo
}