import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import Colaborador from './index'
import Form from "./form";
import Grid from "./grid";

@inject("stateStore", "cargoGridStore", "cargoFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.gridStore = this.props.cargoGridStore
        this.formStore = this.props.cargoFormStore
        this.stateStore = this.props.stateStore

        this.fetchRows()
    }

    render() {
        const { isEditMode } = this.state;

        const gridComponent = (
            <Grid
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        );

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}

                store={this._formStore} />
        );

        return (
            <Colaborador
                isLoading={this._formStore.state === 'pending' || this._gridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        );
    }
}

export default Container;