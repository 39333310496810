import * as React from "react";
import { Page, Card, Button, Form, Grid, } from "tabler-react";
import { observer } from "mobx-react";

@observer
class PageForm extends React.Component {

    render() {
        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Alterar senha
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button color="primary" className="ml-auto" type="button" onClick={e => this.props.onSave()}>
                                    Salvar
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row>
                    <Grid.Col md={2}>
                            <Form.Input
                                label='Senha atual'
                                type='password'
                                onChange={(e) => this.props.store.onChangeCurrentPassword(e.target.value)}
                                value={this.props.store.current_password}
                                feedback={this.props.store.wasValidated ? this.props.store.current_passwordError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.current_passwordError}
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            <Form.Input
                                label='Senha'
                                type='password'
                                onChange={(e) => this.props.store.onChangePassword(e.target.value)}
                                value={this.props.store.password}
                                feedback={this.props.store.wasValidated ? this.props.store.passwordError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.passwordError}
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            <Form.Input
                                label='Repita a senha'
                                type='password'
                                value={this.props.store.password_confirmation}
                                onChange={(e) => this.props.store.onChangePasswordConfirmation(e.target.value)}
                                feedback={this.props.store.wasValidated ? this.props.store.password_confirmationError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.password_confirmationError}
                            />
                        </Grid.Col>

                    </Grid.Row>
                </Page.Card>
            </>
        );
    }
}

export default PageForm;