// compilado de todas as stores.


import StateStore from "@stateStore/StateStore";

import UsuarioGridStore from "../modules/usuario/gridStore";
import UsuarioFormStore from "../modules/usuario/formStore";

import ColaboradorGridStore from "../modules/colaborador/gridStore";
import ColaboradorFormStore from "../modules/colaborador/formStore";

import LancamentoGridStore from "../modules/lancamento/gridStore";
import LancamentoFormStore from "../modules/lancamento/formStore";

import RemessaGridStore from "../modules/remessa/gridStore";
import RemessaFormStore from "../modules/remessa/formStore";

import CargoGridStore from "../modules/cargo/gridStore";
import CargoFormStore from "../modules/cargo/formStore";

import CompetenciaGridStore from "../modules/competencia/gridStore";
import CompetenciaFormStore from "../modules/competencia/formStore";

import ExportacaoGridStore from "../modules/exportacao/gridStore";

import ImportacaoFormStore from "../modules/importacao/formStore";

import RelatorioGridStore from "../modules/relatorio/gridStore";

import SenhaFormStore from "../modules/senha/formStore";

import LoginForm from "@viewStore/LoginViewStore";

export default function () {

    const stateStore = StateStore;

    const usuarioGridStore = UsuarioGridStore;
    const usuarioFormStore = UsuarioFormStore;

    const colaboradorGridStore = ColaboradorGridStore;
    const colaboradorFormStore = ColaboradorFormStore;

    const lancamentoGridStore = LancamentoGridStore;
    const lancamentoFormStore = LancamentoFormStore;

    const remessaGridStore = RemessaGridStore;
    const remessaFormStore = RemessaFormStore;

    const cargoGridStore = CargoGridStore;
    const cargoFormStore = CargoFormStore;

    const competenciaGridStore = CompetenciaGridStore;
    const competenciaFormStore = CompetenciaFormStore;

    const exportacaoGridStore = ExportacaoGridStore;

    const importacaoFormStore = ImportacaoFormStore;

    const loginForm = LoginForm;

    const senhaFormStore = SenhaFormStore;

    const relatorioStore = RelatorioGridStore;

    return {
        loginForm,
        stateStore,

        usuarioGridStore,
        usuarioFormStore,

        lancamentoGridStore,
        lancamentoFormStore,

        remessaGridStore,
        remessaFormStore,

        colaboradorGridStore,
        colaboradorFormStore,

        cargoGridStore,
        cargoFormStore,

        competenciaGridStore,
        competenciaFormStore,

        exportacaoGridStore,

        importacaoFormStore,

        senhaFormStore,

        relatorioStore
    };
}