import React from "react";
import { observer, inject } from "mobx-react";

import BaseContainer from "../../container/BaseContainer";

import DecretoService from "../decreto/service"

import Exportacao from './index'
import Grid from "./grid";

@inject("stateStore", "relatorioStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.stateStore = this.props.stateStore
        this.gridStore = this.props.relatorioStore

        DecretoService._token = this._stateStore.apiToken

        this._gridStore.clearState()
        this.fetchRows()
        this.decretos()
    }

    async decretos() {
        const { data: { rows: decretos } } = await this.getDecretos()
        this._gridStore.setDecretoSelect(decretos)
    }

    async getDecretos() {
        return await DecretoService.fetch()
    }

    showReport(origemId, competencia) {
        const reportName =
            this._gridStore.decreto_id === 'cbff9df4-d596-458c-897e-1ab85189ecd5'
            ? 'Produtividade_ACS' : 'Produtividade'

        const baseUrlRelatorios = this._stateStore.apiReportUrl
        const params = `/${reportName}&ORIGEM_ID=${origemId}&COMPETENCIA=${competencia}&RETIFICACAO=false`

        window.open(baseUrlRelatorios + params);
    }

    render() {
        const gridComponent = (
            <Grid
                isLoading={this._gridStore.state === 'pending'}
                onChangePage={(page) => this.onChangePage(page)}
                showReport={(origemId, competencia) => this.showReport(origemId, competencia)}
                store={this._gridStore}
            />
        );
        return (
            <Exportacao
                component={gridComponent}
            />
        );
    }
}

export default Container;