import React, { useState } from "react"
import { Form } from "tabler-react"
import StringMask from "string-mask"

export default ({ onChange, defaultValue, label, name, placeholder, feedback, invalid }) => {

    let [value, setValue] = useState(defaultValue || '')

    const onChangeField = e => {
        let unmasked = e.target.value.replace(/[^0-9]/g, '').substring(0, 11);
        const cpf = StringMask.apply(unmasked !== '' ? unmasked : '', '###.###.###-##')
        setValue(cpf)
        e.target.value = unmasked
        onChange(e)
    }

    return (
        <Form.Input
            onChange={e => onChangeField(e)}
            label={label}
            name={name}
            value={value}
            type='text'
            placeholder={placeholder}
            feedback={feedback}
            invalid={invalid} />
    )
}