import * as React from "react";
import { Page, Card, Button, Form, Grid, Table, Icon, FormCheckboxInput } from "tabler-react";
import { observer } from "mobx-react";
import InputCpf from '../../components/InputCpfComponent'

@observer
class PageForm extends React.Component {

    render() {
        const rowsLotacoes = this.props.store.lotacoes.map((row, idx) => (
            <Table.Row key={idx} className="myList">
                <Table.Col className="cargos-col-origem">
                    {row.origemLabel}
                </Table.Col>
                <Table.Col>
                    {row.matricula}
                </Table.Col>
                <Table.Col>
                    {row.categoriaLabel}
                </Table.Col>
                <Table.Col>
                    {row.infoAdicionais}
                </Table.Col>
                <Table.Col alignContent="center">
                    <Icon link name="edit" onClick={(() => this.props.onEditLotacao(idx))} />
                    <Icon link name="trash-2" onClick={(() => this.props.onDeleteLotacao(idx))} />
                </Table.Col>
            </Table.Row>
        ))

        const noRows = (
            <Table.Row className="myList">
                <Table.Col colSpan={5} alignContent="center">
                    0 registros foram localizados
                </Table.Col>
            </Table.Row>
        );

        const selectOrigem = (
            <Form.Select
                label="Origem"
                onChange={(e) => this.props.onChangeOrigem(e.target.value)}
                disabled={this.props.store.state === "pending" ? true : false}
                value={this.props.store.formLotacao.origem_id}

                feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.origem_idError : ''}
                invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.origem_idError}
            >
                <option value="">Selecione</option>
                {this.props.store.formLotacao.origemSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.nome}</option>)
                })}
            </Form.Select>
        )

        const selectCategoria = (
            <Form.Select
                label="Categoria"
                onChange={(e) => this.props.store.formLotacao.onChangeCategoria(e.target.value)}
                value={this.props.store.formLotacao.categoria_id}

                feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.categoria_idError : ''}
                invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.categoria_idError}
            >
                <option value="">Selecione</option>
                {this.props.store.formLotacao.categoriaSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.descricao}</option>)
                })}
            </Form.Select>
        )

        const selectCargo = (
            <Form.Select
                label="Cargo"
                onChange={(e) => this.props.store.onChangeCargo(e.target.value)}
                value={this.props.store.formLotacao.cargo_id}
                disabled={this.props.store.formLotacao.disableCargoField}

                feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.cargo_idError : ''}
                invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.cargo_idError}
            >
                <option value="">Selecione</option>
                {this.props.store.formLotacao.cargoSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.simbolo}</option>)
                })}
            </Form.Select>
        )

        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Colaborador
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <Button color="primary" className="ml-auto" type="button" onClick={e => this.props.onSave()}>
                                    {this.props.store.id ? 'Atualizar' : 'Salvar'}
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row>

                        <Grid.Col md={2}>
                            <InputCpf
                                label='CPF'
                                type='text'
                                placeholder="CPF"
                                defaultValue={this.props.store.cpfMask}
                                onChange={(e) => this.props.store.onChangeCpf(e.target.value)}

                                feedback={this.props.store.wasValidated ? this.props.store.cpfError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.cpfError}
                            />
                        </Grid.Col>

                        <Grid.Col md={3}>
                            <Form.Input
                                label='CNS'
                                type='text'
                                placeholder="CNS"
                                defaultValue={this.props.store.cns}
                                onChange={(e) => this.props.store.onChangeCns(e.target.value)}
                            />
                        </Grid.Col>

                        <Grid.Col md={7}>
                            <Form.Input
                                label='Nome'
                                type='text'
                                defaultValue={this.props.store.nome}
                                onChange={(e) => this.props.store.onChangeNome(e.target.value)}
                                placeholder='Nome'

                                feedback={this.props.store.wasValidated ? this.props.store.nomeError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.nomeError} />
                        </Grid.Col>


                    </Grid.Row>

                    <h4>Lotações</h4>
                    <hr />

                    <Form.Group>
                        <Grid.Row>
                            <Grid.Col md={8}>
                                {selectOrigem}
                            </Grid.Col>

                            <Grid.Col md={4}>
                                {selectCategoria}
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={2}>
                                <FormCheckboxInput
                                    label="Trabalha meio período"
                                    onChange={e => this.props.store.formLotacao.onChangeTrabalhaMeioPeriodo(e.target.checked)}
                                    checked={this.props.store.formLotacao.trabalha_meio_periodo}
                                    disabled={this.props.store.formLotacao.disableTrabalhaMeioPeriodoCheck}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={2}>
                                <Form.Input
                                    type='text'
                                    label="Matrícula"
                                    value={this.props.store.formLotacao.matricula}
                                    placeholder='Matrícula'
                                    onChange={(e) => this.props.store.formLotacao.onChangeMatricula(e.target.value)}

                                    feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.matriculaError : ''}
                                    invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.matriculaError} />
                            </Grid.Col>
                            <Grid.Col md={2}>
                                <Form.Input
                                    type='text'
                                    label="Carga horária"
                                    value={this.props.store.formLotacao.cargaHoraria}
                                    placeholder='Carga horária'
                                    maxLength="3"
                                    disabled={this.props.store.formLotacao.disableCHField}
                                    onChange={(e) => this.props.store.formLotacao.onChangeCargaHoraria(e.target.value)}

                                    feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.chError : ''}
                                    invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.chError} />
                            </Grid.Col>

                            <Grid.Col md={3}>
                                <Form.Input
                                    type='text'
                                    label="Carga horária suplementada"
                                    value={this.props.store.formLotacao.cargaHorariaSuplementada}
                                    placeholder='Carga horária suplementada'
                                    maxLength="3"
                                    disabled={this.props.store.formLotacao.disableCHSField}
                                    onChange={(e) => this.props.store.formLotacao.onChangeCargaHorariaSuplementada(e.target.value)} />
                            </Grid.Col>

                            <Grid.Col md={2}>
                                <Form.Input
                                    type='text'
                                    label="Vencimento base"
                                    value={this.props.store.formLotacao.vencimentoBase}
                                    onChange={(e) => this.props.store.formLotacao.onChangeVencimentoBase(e.target.value)}
                                    disabled={this.props.store.formLotacao.disableVencimentoBaseField}
                                    placeholder="Vencimento base"

                                    feedback={this.props.store.formLotacao.wasValidated ? this.props.store.formLotacao.vencimento_baseError : ''}
                                    invalid={this.props.store.formLotacao.wasValidated && this.props.store.formLotacao.vencimento_baseError} />
                            </Grid.Col>


                            <Grid.Col md={3}>
                                {selectCargo}
                            </Grid.Col>
                        </Grid.Row>
                    </Form.Group>

                    <Grid.Row>
                        <Grid.Col md={2}>
                            <Button color="gray"
                                className="ml-auto"
                                type="button"
                                onClick={e => this.props.store.addLotacao()}>
                                {this.props.store.formLotacao.isEditMode ? 'Alterar' : 'Adicionar'}
                            </Button>
                            &nbsp;
                            <Button color="danger"
                                className={`ml-auto ${!this.props.store.formLotacao.isEditMode ? 'd-none' : ''}`}
                                type="button"
                                onClick={e => this.props.onCancelEditLotacao()}>
                                Cancelar
                            </Button>
                        </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap mt-5">
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Origem</Table.ColHeader>
                                    <Table.ColHeader>Matrícula</Table.ColHeader>
                                    <Table.ColHeader>Categoria</Table.ColHeader>
                                    <Table.ColHeader>Detalhes</Table.ColHeader>
                                    <Table.ColHeader alignContent="center">Ações</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rowsLotacoes.length > 0 ? rowsLotacoes : noRows}
                                <Table.Row>
                                    <Table.Col colSpan={5}></Table.Col>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Row>

                </Page.Card>
            </>
        );
    }
}

export default PageForm;