import React, { Component } from "react";

import SiteWrapper from "@components/SiteWrapper.react";

class Exportacao extends Component {
    render() {
        return (
            <SiteWrapper isLoading={false}>
                {this.props.component}
            </SiteWrapper>
        );
    }
}

export default Exportacao;