// Onde tinha o provider

import * as React from "react";
import { Provider } from "mobx-react";

import App from "../Routes";

export default function (stores) { // precisa ser function pra poder chamar como function
    return class Setup extends React.Component {
        render() {
            return (
                <Provider {...stores}>
                    <App />
                </Provider>
            );
        }
    };
}