import React from "react"
import { observer, inject } from "mobx-react"

import BaseContainer from "../../container/BaseContainer"
import { showSwal } from "../../store/ViewStore/Utils"
import usuarioService from "../usuario/service"

import Importacao from './index'
import Form from './form'

@inject("stateStore", "importacaoFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props)
        this.formStore = this.props.importacaoFormStore
        this.stateStore = this.props.stateStore

        usuarioService._token = this._stateStore.apiToken
    }

    import() {
        const swalOptions = {
            title: 'Confirma importação?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ok!',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusCancelButton: true
        }
        showSwal(swalOptions)
            .then(result => {
                if (result.value) {
                    this.store.import()
                }
            })
    }

    render() {
        this.state.isEditMode = true

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}

                store={this._formStore}
                import={this.import}
            />
        )

        return (
            <Importacao
                isLoading={this._formStore.state === 'pending'}
                component={formComponent}
            />
        )
    }
}

export default Container