
import { types, flow } from "mobx-state-tree";
import StateStore from "../../store/StateStore/StateStore";
import api from "./service";

import Validator from 'validatorjs';

const rules = {
    simbolo: 'required',
    remuneracao: 'required'
};

const messages = {
    "required.simbolo": 'A simbologia deve ser preenchida',
    "required.remuneracao": 'A remuneração deve ser preenchida'
};

const Form = types.model({
    id: types.optional(types.string, ""),
    simbolo: types.optional(types.string, ""),
    remuneracao: types.optional(types.number, 0),

    simboloError: types.optional(types.string, ""),
    remuneracaoError: types.optional(types.string, ""),

    isValid: types.optional(types.boolean, false),
    wasValidated: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    stateMessage: types.optional(types.string, "")
})
    .views(self => {
        return {
            get remuneracaoMask() {
                return self.remuneracao.toLocaleString('pt-BR')
            }
        }
    })
    .actions(self => ({

        onChangeSimbolo(value) {
            self.simbolo = value;
            self.validate('simbolo');
        },

        onChangeRemuneracao(value) {
            self.remuneracao = +value;
            self.validate('remuneracao');
        },

        clear() {
            self.id = "";
            self.simbolo = "";
            self.remuneracao = 0;

            self.simboloError = "";
            self.remuneracaoError = "";

            self.isValid = false;
            self.wasValidated = false;
            self.state = "done"
            self.stateMessage = ""
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        validate(field) {

            let _rules = {};
            if (field !== undefined) {
                _rules[field] = rules[field];
            } else {
                _rules = rules;
            }

            let validation = new Validator(self, _rules, messages);

            self.wasValidated = true;
            self.isValid = validation.passes();

            const errors = validation.errors.errors;
            Object.keys(_rules).forEach(function (key) {
                if (errors.hasOwnProperty(key)) {
                    self[key + 'Error'] = errors[key][0];
                } else {
                    self[key + 'Error'] = '';
                }
            });

        },

        fetch: flow(function* fetch(id) {
            self.clear();
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.fetchOne(id);
                if (response.data) {
                    self.id = response.data.id;
                    self.simbolo = response.data.simbolo;
                    self.remuneracao = +response.data.remuneracao;
                }

                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        }),

        save: flow(function* save() {
            self.state = "pending"
            try {
                api._token = StateStore.apiToken

                const response = yield api.save({
                    id: self.id,
                    simbolo: self.simbolo,
                    remuneracao: self.remuneracao
                });

                self.state = "done"
                self.stateMessage = response.message
                self.id = response.data.id
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }));
const form = Form.create({ state: 'done' })
export default form;