import React from "react";
import { observer, inject } from "mobx-react";
import { values } from "mobx"

import BaseContainer from "../../container/BaseContainer";

import Usuario from './index'
import Form from "./form";
import Grid from "./grid";
import origemService from "../origem/service"
import permissionService from "../permissao/service"
import { customToast } from "../../store/ViewStore/Utils"

@inject("stateStore", "usuarioGridStore", "usuarioFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props);

        this.gridStore = this.props.usuarioGridStore
        this.formStore = this.props.usuarioFormStore
        this.stateStore = this.props.stateStore

        origemService._token = this._stateStore.apiToken
        permissionService._token = this._stateStore.apiToken

        // Carregando as origens
        this.fetchOrigens()

        this.fetchRows()
    }

    async showForm(id) {
        try {
            await super.showForm(id)
            this._formStore.changeState("pending")

            // carregandos as permissões cadatradas
            let { data: permissions } = await this.fetchPermissions()
            permissions = Object.entries(permissions).map(([value, label], idx) => {
                return { id: idx, value, label }
            })
            this._formStore.setPermissionsCheck(permissions)

            //setando as permissões do usuário
            this._formStore.userPermissions.forEach(({ name }) => {
                const permission = values(this._formStore.permissions).find(p => p.value === name)
                this._formStore.checkPermission(permission)
            })

            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    fetchOrigens() {
        origemService.fetch()
            .then(json => {
                if (json.data) {
                    const origens = json.data.rows.map(origem => {
                        return { value: origem.id, label: origem.nome }
                    })
                    this._formStore.setOrigemSelect(origens)
                }
            })
    }

    async fetchPermissions() {
        return await permissionService.fetch()
    }

    render() {
        const { isEditMode } = this.state;

        const gridComponent = (
            <Grid
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        );

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}

                store={this._formStore} />
        );

        return (
            <Usuario
                isLoading={this.props.usuarioFormStore.state === 'pending' || this.props.usuarioGridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        );
    }
}

export default Container;