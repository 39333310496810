import * as React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Site, Grid, List, RouterContextProvider, Dimmer } from "tabler-react";
import type { NotificationProps } from "tabler-react";

import { retrieveSettings } from "../../services/storage";

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


type Props = {|
  +children: React.Node,
  +isLoading?: boolean,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

// type subNavItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +LinkComponent?: React.ElementType,
//   +useExact?: boolean,
// |};

// type navItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +active?: boolean,
//   +LinkComponent?: React.ElementType,
//   +subItems?: Array<subNavItem>,
//   +useExact?: boolean,
// |};

const accountDropdownProps = {
  options: [
    { icon: "lock", value: "Alterar senha", to: "/senha" },
    { icon: "log-out", value: "Sair", to: "/login" }
  ],
};

class SiteWrapper extends React.Component<Props, State> {
  static defaultProps = {
    isLoading: false,
  };

  state = {
    notificationsObjects: [
      {
        unread: true,
        avatarURL: "demo/faces/male/41.jpg",
        message: (
          <React.Fragment>
            <strong>Nathan</strong> pushed new commit: Fix page load performance
            issue.
          </React.Fragment>
        ),
        time: "10 minutes ago",
      },
      {
        unread: true,
        avatarURL: "demo/faces/female/1.jpg",
        message: (
          <React.Fragment>
            <strong>Alice</strong> started new task: Tabler UI design.
          </React.Fragment>
        ),
        time: "1 hour ago",
      },
      {
        unread: false,
        avatarURL: "demo/faces/female/18.jpg",
        message: (
          <React.Fragment>
            <strong>Rose</strong> deployed new version of NodeJS REST Api // V3
          </React.Fragment>
        ),
        time: "2 hours ago",
      },
    ],
  };

  render(): React.Node {
    const settings = retrieveSettings();

    accountDropdownProps.avatarURL = "./brasao_sms_icone.png"
    accountDropdownProps.name = (settings && settings.user ? settings.user.name : '')
    accountDropdownProps.description = (settings && settings.user ? settings.user.email : '')

    // Menus
    let menus = []

    if (settings.user.menus) {
      menus = settings.user.menus.map(menu => {
        if (menu.subItems) {
          menu.subItems = menu.subItems.map(subMenu => ({ ...subMenu, LinkComponent: NavLink }))
          return menu
        }
        return { ...menu, LinkComponent: NavLink }
      })
    }

    return (
      <>
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Logo da prefeitura",
          imageURL: "./logo.png",
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: menus }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={this.props.isLoading ? {} : {
          copyright: (
            <React.Fragment>
              2020 ©. SMS COTEC.
            </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">
                  <List.Item className="list-inline-item">
                    <a href="./docs/index.html">Documentation</a>
                  </List.Item>
                  <List.Item className="list-inline-item">
                    <a href="./faq.html">FAQ</a>
                  </List.Item>
                </List>
              </Grid.Col>
            </React.Fragment>
          ),
        }}
      >
        <Dimmer active={this.props.isLoading} loader className={this.props.isLoading ? 'dimmer-content-loading' : ''}>
          {this.props.children}
        </Dimmer>
        <ToastContainer hideProgressBar />
      </Site.Wrapper>
      </>
    );
  }
}

export default SiteWrapper;
