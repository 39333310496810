import { inject, observer } from "mobx-react"
import React from "react"

import BaseContainer from "../../container/BaseContainer"

import { customToast, showSwal } from "../../store/ViewStore/Utils"
import DecretoService from "../decreto/service"
import UsuarioService from "../usuario/service"
import Form from "./form"
import Grid from "./grid"
import Remessa from './index'
import RemessaService from "./service"

@inject("stateStore", "remessaGridStore", "remessaFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props)

        this.gridStore = this.props.remessaGridStore
        this.formStore = this.props.remessaFormStore
        this.stateStore = this.props.stateStore

        DecretoService._token = this._stateStore.apiToken
        UsuarioService._token = this._stateStore.apiToken
        RemessaService._token = this._stateStore.apiToken

        this.fetchRows()
        this.decretos()
    }

    async decretos() {
        const { data: { rows: decretos } } = await this.getDecretos()
        this._gridStore.setDecretoSelect(decretos)
    }

    async calcular(id, recalculo = false) {
        try {
            const lbl = recalculo ? 'recalcular' : 'calcular'
            const resp = await showSwal({
                title: `Deseja ${lbl} essa remessa?`,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

            if (resp.value) {

                if (recalculo) {
                    await this._formStore.save()
                }

                this._formStore.changeState("pending")
                const resp = await RemessaService.calcular(id)
                this._formStore.changeState("done")
                this._formStore.changeStatus("C")
                customToast["info"](resp.message)
            }
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    async showForm(id) {
        try {

            if (id) {
                await this._formStore.fetch(id)
                if (this._formStore.state === 'error')
                    throw new Error(this._formStore.stateMessage)
            } else {
                this._formStore.clear()
            }
            this.onSwitchComponent()

            this._formStore.changeState("pending")
            const respDecretos = await this.getDecretos()

            if (respDecretos.data) {
                this._formStore.setDecretoSelect(respDecretos.data.rows)
            }

            const origens = this.mountOrigemSelect(
                await this.getOrigens()
            )

            // Removendo origens duplicados
            const uniqueOrigens = Array.from(new Set(origens.map(origem => origem.id)))
                .map(id => {
                    return origens.find(origem => origem.id === id)
                })
            this._formStore.setOrigemSelect(uniqueOrigens)
            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    mountOrigemSelect(origens, selectOrigens = []) {
        origens.forEach(origem => {
            if (origem['all_children_origens'].length > 0) {
                return this.mountOrigemSelect(origem['all_children_origens'], selectOrigens)
            }
            selectOrigens.push(origem)
        })

        return selectOrigens
    }

    async getDecretos() {
        return await DecretoService.fetch()
    }

    async getOrigens() {
        return await UsuarioService.getOrigens()
    }

    async revertRemessa(remessaId) {
        try {
            const resp = await showSwal({
                title: `Deseja reabrir os lançamentos? Todas as informações de cálculo da GIP serão apagadas.`,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                focusCancelButton: true,
                reverseButtons: true
            })

            if (resp.value) {
                this._formStore.changeState("pending")
                const resp = await RemessaService.reabrir(remessaId)
                // Recarregando a lista de lancamentos
                await this._gridStore.fetchRows()
                this._formStore.changeState("done")
                customToast["info"](resp.message)
            }
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    render() {
        const { isEditMode } = this.state

        const gridComponent = (
            <Grid
                showForm={(id) => this.showForm(id)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
                revertRemessa={(remessaId) => this.revertRemessa(remessaId)}
            />
        )

        const formComponent = (
            <Form
                onCreate={() => this.onCreate()}
                onSave={() => this.onSave()}
                onBack={() => this.onBack()}
                calcular={(id, recalcular) => this.calcular(id, recalcular)}

                store={this._formStore} />
        )

        return (
            <Remessa
                isLoading={this._formStore.state === 'pending' || this._gridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        )
    }
}

export default Container