import React from 'react'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import Validator from 'validatorjs'

const validator = (form, rules, messages, field) => {
    let _rules = {};
    if (field !== undefined) {
        _rules[field] = rules[field];
    } else {
        _rules = rules;
    }

    let validation = new Validator(form, _rules, messages);

    form.wasValidated = true;
    form.isValid = validation.passes();

    const errors = validation.errors.errors;
    Object.keys(_rules).forEach(function (key) {
        if (errors.hasOwnProperty(key)) {
            form[key + 'Error'] = errors[key][0];
        } else {
            form[key + 'Error'] = '';
        }
    });
}

const showSwal = (options) => {
    if (options.focusCancelButton) {
        options = { ...options, onOpen: () => Swal.getCancelButton().focus() }
    }
    return Swal.fire(options)
}

const customToast = {
    template: (msg, title) => (
        <>
            <div><strong>{title}</strong></div>
            <div dangerouslySetInnerHTML={{ __html: msg }}></div>
        </>
    ),
    info(msg, title = '', options = {}) {
        return toast.info(this.template(msg, title), { ...options, className: 'toast-container toast-info' })
    },
    error(msg, title = '', options = {}) {
        return toast.error(this.template(msg, title), { ...options, className: 'toast-container toast-error' })
    }
}

const formatCurrency = (value, locale, currencyCode) => {
    return (+value).toLocaleString(locale, { style: 'currency', currency: currencyCode })
}

const formatDate = (date, locale = 'pt-BR') => {
    var currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + 1);
    return new Intl.DateTimeFormat(locale).format(currentDate)
}

const filterDigits = (value, previousValue) => {
    value = typeof value === 'string'
        ? +value.replace(/[^0-9]/g, '')
        : value
    if (value > 9 && previousValue === 0) {
        return value / 10
    }
    return value
}

export {
    showSwal,
    validator,
    customToast,
    formatCurrency,
    formatDate,
    filterDigits
}