import { types, flow } from "mobx-state-tree";
import api from "../../services/api";
import JsonType from "@viewStore/JsonType";

export const StateStore = types
    .model({
        user: types.optional(JsonType, []),

        apiToken:       types.optional(types.string, ""),
        apiUri:         types.optional(types.string, "https://produtividade.sms.fortaleza.ce.gov.br/api"),
        apiReportUrl:   types.optional(types.string, "https://reportserver.sms.fortaleza.ce.gov.br/jasperserver/flow.html?j_username=jasperadmin&j_password=jasperadmin&_flowId=viewReportFlow&_flowId=viewReportFlow&standAlone=true&output=pdf&ParentFolderUri=Relatórios/PRODUTIVIDADE/Dinâmicos&reportUnit=/Relatórios/PRODUTIVIDADE/Dinâmicos"),
        
        isAuthenticated: types.optional(types.boolean, false),
    })  
    .actions(self=>({
        setAuthenticated(value){
            self.isAuthenticated = value;
        },

        setApiToken(value){
            self.apiToken = value;
        },

        setUser(value){
            self.user = value;
        },

        requestUser: flow(function* requestUser() {
            
            try {
                const json = yield  api.userInfo(self.apiUri, {
                    token: self.apiToken,
                });
                                  
                //console.log(json);              
                if (json.hasOwnProperty('id')) {
                    self.user = json;
                }
      
            } catch (error) {
                console.log(error.message);
                
                // self.error = error.message;    
            }
        }),

    }));

const Store = StateStore.create({});

export default Store;
