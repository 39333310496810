import { types, flow } from "mobx-state-tree"
import StateStore from "../../store/StateStore/StateStore"
import api from "./service"
import JsonType from "@viewStore/JsonType"
import stringMask from "string-mask"

const GridStore = types
    .model({
        state: types.enumeration("State", ["pending", "done", "error"]),
        stateMessage: types.optional(types.string, ""),

        competencia: types.optional(types.string, ""),
        decreto_id: types.optional(types.string, ''),
        decretoSelect: types.optional(JsonType, []),

        rowsCount: types.optional(types.integer, 0),
        page: types.optional(types.integer, 1),
        limit: types.optional(types.integer, 30),
        offset: types.optional(types.integer, 0),

        rows: types.optional(JsonType, []),
    })
    .views(self => {
        return {
            get getCompetencia() {
                return stringMask.apply(self.competencia, '####/##')
            }
        }
    })
    .actions(self => ({

        onChangeCompetencia(value) {
            self.competencia = value.replace(/[^0-9]/g, '')
            if (self.competencia.length === 6) {
                
            }
        },

        onChangeDecreto(value) {
            self.decreto_id = value
        },

        setDecretoSelect(value) {
            self.decretoSelect = value
        },

        setRows(rows) {
            self.rows = rows
        },

        setState(state) {
            self.state = state
        },

        onChangePage(value) {
            self.page = value
        },

        onChangeOffset(value) {
            self.offset = value
        },

        clearState() {
            self.state = "done"
            self.stateMessage = ""
            self.rows = []
            self.competencia = ''
        },

        clearStateMessage() {
            self.stateMessage = ""
        },

        fetchRows: flow(function* fetchRows() {
            self.rows = []
            self.state = "pending"
            try {
                api._token = StateStore.apiToken
                const json = yield api.fetch(self)
                console.log(json)
                if (json.hasOwnProperty('data')) {
                    self.rows = json.data.rows
                    self.rowsCount = json.data.total
                }
                self.state = "done"
            } catch (error) {
                self.state = "error"
                self.stateMessage = error.message
            }
        })
    }))

export default GridStore.create({ state: 'done' })